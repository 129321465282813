import React, { useEffect, useState } from "react";
import { Empty, Modal,  notification, Input, Pagination, Button } from 'antd';
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { useDebounce } from 'use-debounce';
import { Link, useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { TiCancel } from "react-icons/ti";



interface User {
    userType: number;
    subRegionId:number;
    regionId:number;
    nationality:number;
  }
  interface UserListProps {
    users: User[];
    deleteUserProp:any;
}
const Contacts: React.FC<UserListProps>=({users,deleteUserProp})=>{
    const [usersData, setUsersData] = useState<any[]>([]);
    const nationData = localStorage.getItem('nations');
    const nationList = nationData ? JSON.parse(nationData) : [];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [filterValue, setFilterValue] = useState('');
    const[currentPage,setCurrentPage]=useState<number>(1)
    const [massDelete, setMassDelete] = useState<any[]>([]);
    const [isMassDeleteModalVisible, setIsMassDeleteModalVisible] = useState(false);
    const itemsPerPage=50
    const [displayDelete, setDisplayDelete] = useState(false);
    const [debouncedFilterValue] = useDebounce(filterValue, 300);
    const storedUser = localStorage.getItem('user');
    const User = storedUser ? JSON.parse(storedUser) as User : null;
    const navigate = useNavigate();
    const { darkMode } = useDarkMode();
    const [sortKey, setSortKey] = useState<string>('name'); // Default sorting key
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Default sorting order
    const GetAllUsers = (currentPage:any) => {
        
            if (Array.isArray(users)) {
                setUsersData(users.filter((item: any) => {
                  const userTypeCheck = item?.userType === 8 || item?.userType === 5;
                  const subRegionCheck = item?.subRegionId === User?.subRegionId;
                  const nationalityCheck = item?.nationality === User?.nationality;
                  const statusCheck = statusFilter === 'leadership' ? item.leadershipStatus : statusFilter === 'ambassador' ? item.ambassadorStatus : true;
                  const rankCheck = (item?.ambassadorStatus === false) && (item?.leadershipStatus === false);
              
                  if (User?.userType === 8) {
                    return rankCheck && (
                      (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                      (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                      (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
                    );
                  } else if (User?.userType === 5 || User?.userType === 6 || User?.userType === 3) {
                    return nationalityCheck && (
                      (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                      (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                      (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
                    );
                  } else if (User?.userType === 2 || User?.userType === 7) {
                    return subRegionCheck && (
                      (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                      (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
                      (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
                    );
                  }
                  return false;
                }));
              
            } else {
                console.error('Invalid user data format:', users);
                throw new Error('Invalid user data format.');
            }

          
        
    };

    useEffect(() => {
        GetAllUsers(currentPage);
    }, [debouncedFilterValue, statusFilter]);
    
    const deleteUser = async (id: any) => {
        deleteUserProp(id) 
    };

    const showModal = (id: any) => {
        setUserIdToDelete(id);
        setIsModalVisible(true);
    };
    
    
    const handleEdit = (id: any) => {
        navigate(`/EditAmbassador/${id}`);
    };

    const handleDelete = async () => {
        try {
            await deleteUser(userIdToDelete);
            setIsModalVisible(false);
        } catch (error) {
            // Handle error
        }
    };
    const getNationNameById = (id: any) => {
        if (!id || !Array.isArray(nationList) || nationList.length === 0) {
            return '';
        }
        const nation = nationList.find(n => n.id === id);
        return nation ? nation.name : '';
    };
  const deleteMassUser = async () => {
    try {
        const apiUrl = `https://api.upfafrica.org/admin/user/deleteMany`;
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                ids: massDelete // Send the array of user IDs to be deleted
            }),
        });

        if (!response.ok) {
            console.error('Request failed:', response.status, response.statusText);
            throw new Error('Request failed.');
        }

        const responseData = await response.json();
        console.log('Users deleted successfully:', responseData);

        notification.open({
            message: 'Users Deleted',
            description: 'The users have been deleted successfully.',
            duration: 3,
        });

        GetAllUsers(currentPage); 

        return responseData;
    } catch (error) {
        console.error('Error:', error);

        notification.error({
            message: 'Error Deleting Users',
            description: 'An error occurred while deleting the users.',
            duration: 3,
        });

        return error;
    }
};
  const showMassDeleteModal = () => {
    setIsMassDeleteModalVisible(true);
};
  const handleMassDelete = async () => {
    try {
        await deleteMassUser();
        setIsMassDeleteModalVisible(false);
    } catch (error) {
        // Handle error if needed
    }
};
  const handleCheckboxChange = (id: any) => {
    setMassDelete((prev) => {
        if (prev.includes(id)) {
            return prev.filter((ambassadorId) => ambassadorId !== id);
        } else {
            return [...prev, id];
        }
    });
};
  const handleMany=()=>{
    setMassDelete([])
    setIsModalVisible(false);
    setDisplayDelete(true)
}
  const handlePageChange=(page:number)=>{
    setCurrentPage(page)
}
  const paginatedData=usersData.slice((currentPage-1)*itemsPerPage,currentPage*itemsPerPage)
return(
    <>
    <div className="containerr  rounded-lg overflow-hidden w-[95%] shadow-lg bg-white flex  ml-5 mt-8 ">
                <div className="container mx-auto p-8">
                    <div className="px-6 py-4 w-full">
                        <div className='flex flex-row justify-between mb-7'>
                        <p className="font-bold text-xl mb-2" >Contacts</p>
                        <div>
                        <button
                                onClick={() => navigate("/Contactinput")}
                                style={{ width: 150 }}
                                className={`h-10   mr-3 bg-slate-300 font-bold  px-4 rounded focus:outline-none focus:shadow-outline-blue${darkMode?'border border-blue-500 rounded-md bg-zinc-800 shadow-lg':''}`}
                            >
                                + Add
                            </button>
                           
<Input
    placeholder="Search by name, email, position, or nationality"
    value={filterValue}
    onChange={(e) => setFilterValue(e.target.value)}
    style={{ width: 300 }}
    className="containerrr"
/>
</div>
{displayDelete===true && <div className="mb-4 flex flex-row gap-3">
           
           <button onClick={showMassDeleteModal} className="bg-red-500 text-white py-2 px-4 rounded">
               <FontAwesomeIcon icon={faTrash} className="mr-1" />
           </button>
           <button onClick={()=>setDisplayDelete(false)} className="bg-blue-500 text-white py-2 px-3 rounded  flex justify-center">
               <TiCancel className="w-6 h-6  " />
           </button>
   
       </div>}

                        </div>
                        <table className="w-full">
                        <thead className='text-left'>
                            <tr>
                                <th className="py-2">NO</th>
                                <th className="py-2 px-3">Contact</th>
                                <th className="py-2">Position</th>
                                <th className="py-2">Nationality</th>
                                {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) &&<th className='py-2'>Action</th>}
                            </tr>
                        </thead>
                        {paginatedData?.length > 0 ? (
                            <tbody>
                                {paginatedData.map((user, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-1">{((currentPage-1)*itemsPerPage+index + 1)}</td>
                                        <Link to={`/individual/${user?.id}`} >
                                        <td className="py-2 px-3">{user.name}</td></Link>
                                        <td className="py-2 ">{user.position||user.upfPosition}</td>
                                        <td className="py-2 w-1/4">{user.nationality && getNationNameById(user.nationality)}</td>
                                        {(User?.userType === 8 || User?.userType === 2 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) && (
                                            <td className="py-2 flex flex-row">
                                            {displayDelete===false &&  <FontAwesomeIcon
                                                onClick={() => showModal(user?.id)}
                                                className="cursor-pointer "
                                                color="#FF0000"
                                                icon={faTrash}
                                            />
 }
                                            <button
                                className="text-blue-500 mr-4 ml-2"
                                onClick={() => navigate(`/editAmbassador/${user?.id}`)}
                            >
                                <CiEdit size={20} />
                            </button>
                          {displayDelete===true &&<input
                                type="checkbox"
                                checked={massDelete.includes(user?.id)}
                                onChange={() => handleCheckboxChange(user?.id)}
                            />}
                                        </td>
                                    )}
                                </tr>
                            ))}
                            </tbody>
                        ) : <Empty description="No Data Found" />}

                    </table>
                    <Pagination
                       current={currentPage}
                       pageSize={itemsPerPage}
                       total={usersData.length}
                       onChange={handlePageChange}
                       style={{ textAlign: 'center', marginTop: '20px' }}
                    />
                    <Modal
                title="Delete User"
                visible={isModalVisible}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                    Cancel
                </Button>,
                <Button key="many" type="primary" onClick={handleMany} className='bg-blue-500'>
                    Many
                </Button>,
                <Button key="delete" type="primary" danger onClick={handleDelete}>
                    Delete
                </Button>,
                ]}
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete only this user?</p>
            </Modal>
            <Modal
                title="Mass Delete Users"
                visible={isMassDeleteModalVisible}
                onOk={handleMassDelete}
                onCancel={() =>{setIsMassDeleteModalVisible(false);
                    setDisplayDelete(false)}}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete the selected users?</p>
            </Modal>
                    </div>
                </div>
                </div>
            
    </>
)
}
export default Contacts