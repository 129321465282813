import React, { useEffect, useState } from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input, Modal, notification, Pagination } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDebounce } from 'use-debounce';
import { CiEdit } from "react-icons/ci";
import { TiCancel } from "react-icons/ti";
import { useDarkMode } from '../../contextApis/DarkModeContext';

interface User {
  id: number;
  name: string;
  upfPosition:string;
  position: string;
  nationality: number;
  ambassadorStatus: boolean;
  subRegionId: number;
  userType: any;
}

interface UserListProps {
  users: User[];
  deleteUserProp :any;
}

const Ambassador0: React.FC<UserListProps> = ({ users, deleteUserProp  }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMassDeleteModalVisible, setIsMassDeleteModalVisible] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
  const [ambassadorData, setAmbassadorData] = useState<User[]>([]);
  const nationData = localStorage.getItem('nations');
  const nationList = nationData ? JSON.parse(nationData) : [];
  const [massDelete, setMassDelete] = useState<number[]>([]);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const storedUser = localStorage.getItem('user');
  const { darkMode } = useDarkMode();
  const user = storedUser ? JSON.parse(storedUser) as User : null;
  const [debouncedFilterValue] = useDebounce(filterValue, 300);

  const GetAllUsers = () => {
    if (users) {
      const filteredData = users.filter((item: User) => {
        const subRegionCheck = item.subRegionId === user?.subRegionId;
        const nationalityCheck = item.nationality === user?.nationality;
        const ambassadorStatusCheck = item.ambassadorStatus;
  
        if (user?.userType === 8) {
          return (
            ambassadorStatusCheck &&
            (item.name?.toLowerCase().includes(debouncedFilterValue.toLowerCase()) ||
             item.position?.toLowerCase().includes(debouncedFilterValue.toLowerCase()) ||
             getNationNameById(item.nationality)?.toLowerCase().includes(debouncedFilterValue.toLowerCase()))
          );
        } else if ([5, 6, 3].includes(user?.userType)) {
          return (
            nationalityCheck &&
            ambassadorStatusCheck &&
            (item.name?.toLowerCase().includes(debouncedFilterValue.toLowerCase()) ||
             item.position?.toLowerCase().includes(debouncedFilterValue.toLowerCase()) ||
             getNationNameById(item.nationality)?.toLowerCase().includes(debouncedFilterValue.toLowerCase()))
          );
        } else if ([2, 7].includes(user?.userType)) {
          return (
            subRegionCheck &&
            ambassadorStatusCheck &&
            (item.name?.toLowerCase().includes(debouncedFilterValue.toLowerCase()) ||
             item.position?.toLowerCase().includes(debouncedFilterValue.toLowerCase()) ||
             getNationNameById(item.nationality)?.toLowerCase().includes(debouncedFilterValue.toLowerCase()))
          );
        } else {
          return false;
        }
      });
      setAmbassadorData(filteredData);
    }
  };
  
  useEffect(() => {
    GetAllUsers();
  }, [debouncedFilterValue, users]); 
    const deleteUser = async (id: any) => {
        deleteUserProp(id) 
    };

    const deleteMassUser = async () => {
        try {
            const apiUrl = `https://api.upfafrica.org/admin/user/deleteMany`;
            const token = sessionStorage.getItem('token');

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    ids: massDelete // Send the array of user IDs to be deleted
                }),
            });

            if (!response.ok) {
                console.error('Request failed:', response.status, response.statusText);
                throw new Error('Request failed.');
            }

            const responseData = await response.json();
            console.log('Users deleted successfully:', responseData);

            notification.open({
                message: 'Users Deleted',
                description: 'The users have been deleted successfully.',
                duration: 3,
            });

            GetAllUsers(); 

            return responseData;
        } catch (error) {
            console.error('Error:', error);

            notification.error({
                message: 'Error Deleting Users',
                description: 'An error occurred while deleting the users.',
                duration: 3,
            });

            return error;
        }
    };

    const showModal = (id: any) => {
        setUserIdToDelete(id);
        setIsModalVisible(true);
    };

    const showMassDeleteModal = () => {
        setIsMassDeleteModalVisible(true);
    };

    const handleDelete = async () => {
        try {
            await deleteUser(userIdToDelete);
            setIsModalVisible(false);
        } catch (error) {
        }
    };

    const handleMassDelete = async () => {
        try {
            await deleteMassUser();
            setIsMassDeleteModalVisible(false);
        } catch (error) {
            // Handle error if needed
        }
    };

    const handleCheckboxChange = (id: any) => {
        setMassDelete((prev) => {
            if (prev.includes(id)) {
                return prev.filter((ambassadorId) => ambassadorId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    useEffect(() => {
        console.log('Debounced Filter Value:', debouncedFilterValue);
        GetAllUsers();
    }, [debouncedFilterValue]);
    
    const handleMany=()=>{
        setMassDelete([])
        setIsModalVisible(false);
        setDisplayDelete(true)
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValue(e.target.value);
        setCurrentPage(1); // Reset to the first page when the filter changes
    };

    const getNationNameById = (id: number) => {
        const nation = nationList.find((nation:any) => nation.id === id);
        return nation ? nation.name : 'Unknown';
    };

    const paginatedAmbassadorData = ambassadorData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    if (!user) {
        return (
            <div className={`flex justify-center items-center ${darkMode ? 'bg-gray-800' : 'bg-white'} min-h-screen`}>
                <div className="text-center">
                    <FadeLoader color={darkMode ? '#ffffff' : '#000000'} />
                    <p className={`mt-4 ${darkMode ? 'text-white' : 'text-black'}`}>Please login to view this page</p>
                    <button onClick={() => navigate('/login')} className="bg-blue-500 text-white py-2 px-4 rounded mt-2">
                        Login
                    </button>
                </div>
            </div>
        );
    }
    const toTitleCase = (str:any) => {
        return str.replace(/\w\S*/g, (txt:any) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };
    return (
        <div className={`container  containerr rounded-lg bg-white text-black p-10`}>
            <h1 className="text-2xl font-bold mb-4">Ambassadors</h1>
            <div className='flex flex-row justify-between'>
            <Input
                placeholder="Search by name, position, or nationality"
                value={filterValue}
                onChange={handleInputChange}
                className="mb-4 w-64"
            />
             <button
            onClick={()=>navigate('/Ambassadorinput')}
            className="w-2/12 h-10 mr-3 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue containerr "
          >
            + Add
          </button>
          {displayDelete===true && <div className="mb-4 flex flex-row gap-3">
           
           <button onClick={showMassDeleteModal} className="bg-red-500 text-white py-2 px-4 rounded">
               <FontAwesomeIcon icon={faTrash} className="mr-1" />
           </button>
           <button onClick={()=>setDisplayDelete(false)} className="bg-blue-500 text-white py-2 px-3 rounded  flex justify-center">
               <TiCancel className="w-6 h-6  " />
           </button>
   
       </div>}
            </div>
            <table className="w-full">
                        <thead className='text-left'>
                            <tr>
                                <th className="py-2">NO</th>
                                <th className="py-2 px-3">Ambassador</th>
                                <th className="py-2">Position</th>
                                <th className="py-2">Nationality</th>
                                <th className='py-2'>Action</th>
                            </tr>
                        </thead>
                        {paginatedAmbassadorData?.length > 0 ? (
                            <tbody>
                                {paginatedAmbassadorData.map((ambassador, index) => (
                                    <tr key={index}>
                                        <td className="py-2 px-1">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <Link to={`/individual/${ambassador?.id}`} >
                                        <td className="py-2 px-3">{ambassador.name}</td></Link>
                                        <td className="py-2 ">{((ambassador.position || ambassador.upfPosition) && (ambassador.position || ambassador.upfPosition).split(' ').slice(0, 106).join(' '))}</td>
                                        <td className="py-2 w-1/4">{ambassador.nationality && getNationNameById(ambassador.nationality)}</td>
                                        {(user?.userType === 8 || user?.userType === 2 || user?.userType === 4 || user?.userType === 7 || user?.userType === 6) && (
                                            <td className="py-2 flex flex-row">
                                                {displayDelete===false &&  <FontAwesomeIcon
                                                    onClick={() => showModal(ambassador?.id)}
                                                    className="cursor-pointer "
                                                    color="#FF0000"
                                                    icon={faTrash}
                                                />
}
                                                <button
                                    className="text-blue-500 mr-4"
                                    onClick={() => navigate(`/editAmbassador/${ambassador.id}`)}
                                >
                                    <CiEdit size={20} />
                                </button>
                              {displayDelete===true &&<input
                                    type="checkbox"
                                    checked={massDelete.includes(ambassador.id)}
                                    onChange={() => handleCheckboxChange(ambassador.id)}
                                />}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={5}>
                                        {user && user.userType !== 8 && (
                                            <div className="min-h-screen flex flex-col justify-center items-center px-4">
                                                <div className="text-center max-w-lg">
                                                    <FontAwesomeIcon icon={faLock} size="6x" className="text-red-600 mb-4" />
                                                    <h1 className="text-3xl font-bold mb-2">Not Enough Permissions or No Data Exists</h1>
                                                    <p className="mb-6">
                                                        Please contact Superiors to confirm your Authorizations Limits
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {user && user.userType === 8 && (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                <FadeLoader color="#367fd6" className='w-96 h-96' />
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
            <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={ambassadorData.length}
                onChange={handlePageChange}
                className="mt-4"
            />
            <Modal
                title="Delete User"
                visible={isModalVisible}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                    Cancel
                </Button>,
                <Button key="many" type="primary" onClick={handleMany} className='bg-blue-500'>
                    Many
                </Button>,
                <Button key="delete" type="primary" danger onClick={handleDelete}>
                    Delete
                </Button>,
                ]}
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete only this user?</p>
            </Modal>
            <Modal
                title="Mass Delete Users"
                visible={isMassDeleteModalVisible}
                onOk={handleMassDelete}
                onCancel={() =>{setIsMassDeleteModalVisible(false);
                    setDisplayDelete(false)}}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                <p>Are you sure you want to delete the selected users?</p>
            </Modal>
        </div>
    );
}

export default Ambassador0;
