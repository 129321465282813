import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaChevronLeft } from "react-icons/fa";
import axios from "axios";

interface EventFormData {
  name: string;
  type: string;
  theme: string;
  status:string
  city: string;
  host: string;
  nationId: number;
  regionId: number;
  subRegionId: number;
  date: Date | null;
  description: string; 
  coverImage: string;
  gallery: [];
}
interface DropdownOption {
  id: number;
  name: string;
}
interface ser {
  id:string
  userType: number;
  subRegionId:number;
  nationality:number
}
interface GalleryData {
  url: [];
}

const EventForm: React.FC = () => {
  const regionData = localStorage.getItem('regions');
  const regionOptions = regionData ? JSON.parse(regionData) : [];
  const nationData = localStorage.getItem('nations');
  const nationalityOptions = nationData ? JSON.parse(nationData) : [];
  const subRegionData = localStorage.getItem('subRegions');
  const subRegionOptions = subRegionData ? JSON.parse(subRegionData) : [];
  const[error,setError]=useState('')
  const [imageURL, setImageURL] = useState<string>('');
  const [gallery, setGallery] = useState<GalleryData[]>([]);
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as ser : null;
  const [formData, setFormData] = useState<EventFormData>({
    name: '',
    type: '',
    city: '',
    theme: '',
    coverImage: '',
    description: '',
    gallery: [],
    host: '',
    status: 'Pending',
    nationId: 1,
    subRegionId: 1,
    regionId: 1,
    date: null,
  });
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      coverImage: imageURL,
    }));
  }, [imageURL]);
  useEffect(() => {
   
      subRegionOptions.filter((item: any) => {
               // const userTypeCheck = item?.userType === 8 || item?.userType === 5;
                const subRegionCheck = item?.id === user?.subRegionId;

                if (user?.userType === 8) {
                    return true
                }
                else if (user?.userType === 2 )  {
                    return  subRegionCheck 
                } 
                else if (user?.userType === 3 || user?.userType === 5 || user?.userType === 6 )  {
                  return subRegionCheck
                }else {
                    return false;
                }
              })
      
        nationalityOptions?.filter((item: any) => {
               // const userTypeCheck = item?.userType === 8 || item?.userType === 5;
                const subRegionCheck = item?.subRegionId === user?.subRegionId;
                const nationalCheck = item?.id === user?.nationality;
                console.log(nationalCheck)
                if (user?.userType === 8) {
                    return true
                }
                else if (user?.userType === 2 )  {
                    return  subRegionCheck 
                } 
                else if (user?.userType === 3 || user?.userType === 5 || user?.userType === 6 )  {
                  return nationalCheck
                }else {
                    return false;
                }
              })

  }, []);
  const handleChange = (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : name === 'regionId' || name === 'nationId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);
  
      // Upload image with robust error handling
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${"e25ca86e136c8772548b88111b46546196e5a2d473e8328497f69a3a21936d3930d4ca4018fa414ab7b4b84dae67d26e0be033ccffd82e6511bca3929204f2326837c520ee51126ee701a6aba146f4abd9ee24d3e9a183e38f065f0bde5f42628978d36e727ade3110d7b64a00bda8ae7616201dc56e0915e4f3920030cdc70e"}`, // Replace with your actual token
          },
        }
      );
  
      if (!response.status || !response.data) {
        throw new Error('Failed to upload image');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastImageUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setImageURL(lastImageUrl);
        console.log('Image uploaded successfully:', imageURL);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Image upload error:', error);
      // Handle error (e.g., display a message to the user)
    }
  };

  const handleGalleryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files!);
  
    if (files.length > 9) {
      alert('You can only upload up to 9 images');
      return files.length; // Return the number of files for checking in rendering
    }
  
    try {
      const formData = new FormData();
  
      for (let i = 0; i < files.length && i < 9; i++) {
        formData.append('files', files[i]);
      }
  
      const response = await axios.post<GalleryData[]>(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${"e25ca86e136c8772548b88111b46546196e5a2d473e8328497f69a3a21936d3930d4ca4018fa414ab7b4b84dae67d26e0be033ccffd82e6511bca3929204f2326837c520ee51126ee701a6aba146f4abd9ee24d3e9a183e38f065f0bde5f42628978d36e727ade3110d7b64a00bda8ae7616201dc56e0915e4f3920030cdc70e"}`, // Replace with your actual token
          },
        }
      );
      if (!response.status || !response.data) {
        throw new Error('Failed to upload images');
      }
      const uploadedImageURLs: GalleryData[] = response.data
      .filter((image) => typeof image !== 'undefined') // Filter out empty arrays
      .map((image: { url: [] }) => ({ url: image.url }));
      const newGallery = [...gallery, ...uploadedImageURLs];
      if (newGallery.length <= 9) {
        setGallery(newGallery);
        console.log("gallery",gallery)
      }else{
        setError("Only Upto 9 images are accepted to be uploaded")
      }
    } catch (error) {
      console.error('Image upload error:', error);
      // Handle error (e.g., display a message to the user)
    }
  };
  

  const handleSelectChange = (name: keyof EventFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData!,
      [name]: parseInt(value, 10),
    });
  };
  const handleDateChange = (date: Date | null) => {
    setFormData({
      ...formData,
      date,
    });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = sessionStorage.getItem('token');
    if (!token) {
      console.error('Token not found in sessionStorage');
      return;
    }

    try {
      const finalFormData = {
        ...formData,
        gallery: gallery.map(item => item.url)  
      };
      console.log(finalFormData)
      const response = await fetch('https://api.upfafrica.org/admin/activity/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(finalFormData),
      });
      const responseData = await response.json();
      if (!response.ok) {
        const errorMessage = responseData && responseData.error ? responseData.error.message : 'Unknown error';
        console.error('Failed to submit form:', errorMessage);
      } else {
        navigate('/Summits');
        console.log(responseData);
      }
      
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  });
  const back=()=>{
    navigate(-1)
   }
  return (
    <>
    <button onClick={back} className='flex flex-row mb-4 hover'>
    <FaChevronLeft className='w-6 h-6' />
     <p className='text-xl font-semibold ml-3'>Back</p>
    </button>
    <form className="max-w mx-auto p-4 " onSubmit={handleSubmit}  style={getTitleStyle()}>
      <label className=" mb-2 flex flex-col">
        Name:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        Type:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="type"
          value={formData.type}
          onChange={handleChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        Theme:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="theme"
          value={formData.theme}
          onChange={handleChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        Date:
        <DatePicker
          className="w-full p-2 border rounded-md mt-1"
          selected={formData.date}
          onChange={handleDateChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        host:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="host"
          value={formData.host}
          onChange={handleChange}
        />
      </label>
      <label className="flex flex-col mb-2">
  Description:
  <textarea
    className="w-full p-2 border rounded-md mt-1 h-3"
    name="description"
    value={formData.description}
    onChange={handleChange}
  />
</label>
      <label className="flex flex-col mb-2">
        city:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="city"
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
      </label>
      
      
      <label className="block mb-2">
        Region:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="regionId"
          value={formData?.regionId}
          onChange={handleSelectChange('regionId')}
        >
          <option value="">Select a region</option>
          {regionOptions.map((region:any) => (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          ))}
        </select>
      </label>
      {/* Your existing form inputs */}
      <label className="block mb-2">
        Sub-Region:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="subRegionId"
          value={formData?.subRegionId}
          onChange={handleSelectChange('subRegionId')}
        >
          <option value="">Select a sub-region</option>
          {subRegionOptions.map((subRegion:any) => (
            <option key={subRegion.id} value={subRegion.id}>
              {subRegion.name}
            </option>
          ))}
        </select>
      </label>
      <label className="block mb-2">
        Nationality:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="nationality"
          value={formData?.nationId}
          onChange={handleSelectChange('nationId')}
        >
          <option value="">Select a nationality</option>
          {nationalityOptions.map((nationality:any)=> (
            <option key={nationality.id} value={nationality.id}>
              {nationality.name}
            </option>
          ))}
        </select>
      </label>
     
      <label className="block mb-2">
        Cover Image:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="file"
          name="coverImage"
          onChange={handleImageChange}
        />
      </label>

      <img src={`https://fm.upfafrica.org${imageURL}`} alt="Uploaded" className='w-1/4 h-1/4 object-cover rounded-lg mb-4' />

      <div>
      <label className="block mb-2">
        Gallery: (Max 9)
        <input
          type="file"
          multiple
          onChange={handleGalleryChange}
        />
      </label>

      {File?.length > 9 && ( // Check length of files only if it exists
      <div className="alert alert-danger" role="alert">
        You can only upload up to 9 images!
      </div>
    )}

    {gallery.length > 0 && ( // Display images only if there are any
      <div className="grid grid-cols-3 gap-4">
        {gallery.map((image, index) => (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            key={index}
            src={`https://fm.upfafrica.org${image}`}
            alt={`Uploaded image ${index + 1}`}
            className="w-full h-48 object-cover rounded-lg"
          />
        ))}
      </div>
    )}
    <p className="text-red-600">{error}</p>
    </div>

      <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" type="submit">
        Submit
      </button>
    </form>
    </>
  );
};

export default EventForm;
