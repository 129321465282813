import React, { useState, ChangeEvent, FormEvent,useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {  useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import ReligionSelect from '../../globals/ReligionSelect';
import axios from "axios";

interface AmbassadorFormData {
  name: string;
  religion: string;
  gender: string;
  email: string;
  upfPosition: string;
  honorifics: string;
  username: string;
  primaryTag: number;
 otherTags: string;
  nationality:number;
  regionId: number,
  subRegionId: number,
  userType: number,
   mobileNo: string,
   leadershipStatus: boolean;
  ambassadorStatus: boolean;
  biography:string;
  picture: string;
  pointEmail:string;
  city: string;
  street:string;
  twitterID: string;
  linkedinID:string;
  instagramID: string;
  facebookID:string;
  organization: string;
}
interface Nation {
  id: number;
  name: string;
}
interface User {
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}
const Leader: React.FC = () => {
  const nationData = localStorage.getItem('nations');
  const nationList = nationData ? JSON.parse(nationData) : [];
  const [associationList, setAssociationList] = useState<Nation[]>([]);
  const subRegionData = localStorage.getItem('subRegions');
  const subRegionList = subRegionData ? JSON.parse(subRegionData) : [];
  const[pageNumber,setPageNumber]=useState<number>(1)
  const [imageURL, setImageURL] = useState<string>('');
  const [fileURL, setFileURL] = useState<string>('');
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User:null ;
  const [formData, setFormData] = useState<AmbassadorFormData>({
    name: '',
    religion: '',
    email: '',
    gender: '',
    upfPosition: '',
    honorifics: '',
    username: '',
    primaryTag: 1,
    nationality: 1,
    subRegionId: 1,
    regionId: 1,
    userType: 1,
    biography:'',
    otherTags: '',
    mobileNo: '',
    picture: '',
    pointEmail:'',
    city: '',
    street:'',
    twitterID: '',
    linkedinID:'',
    instagramID: '',
    facebookID:'',
    organization: '',
    leadershipStatus: true,
    ambassadorStatus: false,
  });
  const regionData =localStorage.getItem('regions');
  const regionList= regionData ? JSON.parse(regionData) : [];
  const { darkMode } = useDarkMode();
  const [emailError, setEmailError] = useState('');
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      picture: imageURL,
      biography:fileURL,
    }));
  }, [imageURL,fileURL]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const nationResponse = await fetch('https://api.upfafrica.org/admin/association/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: ['id', 'name'],
              paginate: 166,
            },
            isCountOnly: false,
          }),
        });
        const nationData = await nationResponse.json();
        if (nationData?.data) {
          setAssociationList(nationData.data.data);
        } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
        }
      } catch (error) {
        console.error('Error fetching nation data:', error);
      }
    };
    fetchData();
  }, []);
  const handleSelectChange = (name: keyof AmbassadorFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData!,
      [name]: parseInt(value, 10),
    });
  };
  const handleSelectGenderChange = (name: keyof AmbassadorFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData!,
      [name]: value,
    });
  };
  

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);
  
      // Upload image with robust error handling
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${"e25ca86e136c8772548b88111b46546196e5a2d473e8328497f69a3a21936d3930d4ca4018fa414ab7b4b84dae67d26e0be033ccffd82e6511bca3929204f2326837c520ee51126ee701a6aba146f4abd9ee24d3e9a183e38f065f0bde5f42628978d36e727ade3110d7b64a00bda8ae7616201dc56e0915e4f3920030cdc70e"}`, // Replace with your actual token
          },
        }
      );
  
      if (!response.status || !response.data) {
        throw new Error('Failed to upload image');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastImageUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setImageURL(lastImageUrl);
        console.log('Image uploaded successfully:', imageURL);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Image upload error:', error);
      // Handle error (e.g., display a message to the user)
    }
  };


  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    const formData = new FormData();
    formData.append('image', file);
    //const token = localStorage.getItem('token');

    // Upload image file to server
    fetch('https://api.upfafrica.org/admin/cloudinaryUpload', {
      method: 'POST',
      // headers: {
      //   'Authorization': `Bearer ${token}`,
      // },
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to upload image');
        }
        return response.json();
      })
      .then(data => {
        // Update image URL state with the uploaded image URL
        setFileURL(data.data.url || '');
      })
      .catch(error => {
        console.error('Error uploading image:', error);
        // Handle error (e.g., display a message to the user)
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (name === "email") {
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
      if (!emailPattern.test(value)) {
        setEmailError("Invalid email address");
      } else {
        setEmailError("");
      }
    }
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : name === 'nationality' ||name === 'userType' ||name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  const handlePhoneChange = (value:any) => {
    setFormData((prevData) => ({
      ...prevData,
      mobileNo: value,
    }));
  };
  const navigate = useNavigate();
  // const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (typeof reader.result === 'string') {
  //         setFormData({
  //           ...formData,
  //           picture: reader.result,
  //         });
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  
  if (emailError) {
    console.error('Invalid Email');
    return;
  }

  if (formData.regionId === 0) {
    console.error('Please select a Region');
    return;
  }
  if (formData.name === '') {
    console.error('Please add a Name');
    return;
  }
  if (formData.subRegionId === 0) {
    console.error('Please select a Sub-Region');
    return;
  }

  if (formData.nationality === 0) {
    console.error('Please select a Nationality');
    return;
  }


  const token = sessionStorage.getItem('token');
  if (!token) {
    console.error('Token not found in localStorage');
    return;
  }

  try {
    console.log('Token:', token);
    console.log('Form Data:', formData);

    const response = await fetch('https://api.upfafrica.org/admin/user/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    // Attempt to parse the response
    let responseData;
    try {
      responseData = await response.json();
    } catch (jsonError) {
      console.error('Failed to parse response JSON', jsonError);
      return;
    }

    if (!response.ok) {
      console.error('Failed to submit form', responseData);
      alert(`Failed to submit form: ${responseData.message || 'Unknown error'}`);
    } else {
      console.log(responseData);
      navigate('/Leadership');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An unexpected error occurred. Please try again later.');
  }
};

  const back=()=>{
    navigate(-1)
   }
   const backPage=()=>{
    navigate(pageNumber-1)
   }

  

   return (
    <div>
      {(formData.userType === 8 || formData.userType === 4 || formData.userType === 7 || formData.userType === 6) && (
        <button onClick={back} className='flex flex-row mb-4 hover'>
          <FaChevronLeft className='w-6 h-6' />
          <p className='text-xl font-semibold ml-3'>Back</p>
        </button>
      )}

      <form className="max-w mx-auto p-4" onSubmit={handleSubmit} style={getTitleStyle()}>
      
        {pageNumber === 1 && (
          <>
            <label className="block mb-2">
              Honorifics:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="honorifics"
                value={formData.honorifics}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Name:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </label>
            {formData.name === '' && <p className="text-red-500 mt-1">Please add a Name</p>}
            <label className="block mb-2">
              Religion:
              <ReligionSelect formData={formData} setFormData={setFormData} />
            </label>
            <label className="block mb-2">
              Gender:
              <select
                className="w-full p-2 border rounded-md mt-1"
                name="gender"
                value={formData.gender}
                onChange={handleSelectGenderChange('gender')}
              >
                <option value=''>Select Gender</option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
                <option value='Other'>Other</option>
              </select>
            </label>
            <label className="block mb-2">
              Mobile No:
              <div className="flex">
                <PhoneInput
                  country={'ke'}
                  value={formData.mobileNo}
                  onChange={handlePhoneChange}
                  inputClass={`w-full p-2 border rounded-md mt-1 ${darkMode ? 'dark-mode-input' : ''}`}
                  buttonClass={`border rounded-l-md ${darkMode ? 'dark-mode-button' : ''}`}
                  dropdownClass={`dropdown-class ${darkMode ? 'dark-mode-dropdown' : ''}`}
                />
              </div>
            </label>
            <label className="block mb-2">
              UpfPosition:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="upfPosition"
                value={formData.upfPosition}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Email:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
              {emailError && <p className="text-red-500 mt-1">{emailError}</p>}
            </label>
            <label className="block mb-2">
              Biography:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="file"
                name="picture"
                onChange={handleFileChange}
              />
            </label>
            <label className="block mb-2">
              UserName:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-7 mt-7 w-full p-2 border rounded-md containerrr">
              Ambassador Status:
              <input
                className="ml-2 p-6 w-5 h-5"
                type="checkbox"
                name="ambassadorStatus"
                checked={formData.ambassadorStatus}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Nationality:
              <select
                className="w-full p-2 border rounded-md mt-1"
                name="nationality"
                value={formData.nationality}
                onChange={handleSelectChange('nationality')}
              >
                <option value={0}>Select a nation</option>
                {nationList.map((nation:any) => (
                  <option key={nation.id} value={nation.id}>
                    {nation.name}
                  </option>
                ))}
              </select>
              {formData.nationality === 1 && <p className="text-red-500 mt-1">Please select a Nation</p>}
            </label>
            <label className="block mb-2">
              User Type:
              <select
                className="w-full p-2 border rounded-md mt-1"
                name="userType"
                value={formData.userType}
                onChange={handleSelectChange('userType')}
              >
                <option value={0}>Select a user type</option>
                <option value={1}>Regional Coordinator</option>
                <option value={2}>SR Leader</option>
                <option value={3}>National Leader</option>
                <option value={4}>Association Chairman/President</option>
                <option value={5}>National Coordinator</option>
                <option value={6}>National SG</option>
                <option value={7}>SR Admin</option>
                <option value={8}>Super Admin</option>
                <option value={9}>System User</option>
                <option value={10}>User</option>
              </select>
            </label>
            <label className="block mb-2">
              Region:
              <select
                className="w-full p-2 border rounded-md mt-1"
                name="regionId"
                value={formData.regionId}
                onChange={handleSelectChange('regionId')}
              >
                <option value={0}>Select a region</option>
                {regionList.map((region:any)=> (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
              {formData.regionId === 1 && <p className="text-red-500 mt-1">Please select a Region</p>}
            </label>
            <label className="block mb-2">
              Sub-Region:
              <select
                className="w-full p-2 border rounded-md mt-1"
                name="subRegionId"
                value={formData.subRegionId}
                onChange={handleSelectChange('subRegionId')}
              >
                <option value={0}>Select a sub-region</option>
                {subRegionList.map((subRegion:any)=> (
                  <option key={subRegion.id} value={subRegion.id}>
                    {subRegion.name}
                  </option>
                ))}
              </select>
              {formData.subRegionId === 1 && <p className="text-red-500 mt-1">Please select a Sub-Region</p>}
            </label>
            <label className="block mb-2">
              Primary Association:
              <select
                className="w-full p-2 border rounded-md mt-1"
                name="primaryTag"
                value={formData.primaryTag}
                onChange={handleSelectChange('primaryTag')}
              >
                <option value={0}>Select a Primary Association</option>
                {associationList.map(association => (
                  <option key={association.id} value={association.id}>
                    {association.name}
                  </option>
                ))}
              </select>
            </label>
            <label className="block mb-2">
              Secondary Association:
              <select
                className="w-full p-2 border rounded-md mt-1"
                name="otherTags"
                value={formData.otherTags}
                onChange={handleSelectChange('otherTags')}
              >
                <option value={0}>Select a Secondary Association</option>
                {associationList.map(association => (
                  <option key={association.id} value={association.id}>
                    {association.name}
                  </option>
                ))}
              </select>
            </label>
            <label className="block mb-2">
              Picture:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="file"
                name="picture"
                onChange={handleImageChange}
              />
            </label>
            <img src={`https://fm.upfafrica.org${imageURL}`} alt="Uploaded" className='w-1/4 h-1/4 object-cover rounded-lg mb-4' />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              Next
            </button>
          </>
        )}
        {pageNumber === 2 && (
          <>
          <button onClick={()=>backPage()} className='flex flex-row mb-4 hover'>
          <FaChevronLeft className='w-6 h-6' />
          <p className='text-xl font-semibold ml-3'>Back</p>
        </button>
        <label className="block mb-2">
              City:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="honorifics"
                value={formData.city}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Street:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="honorifics"
                value={formData.street}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Organization:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="honorifics"
                value={formData.organization}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              pointEmail:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="honorifics"
                value={formData.pointEmail}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Twitter:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="name"
                value={formData.twitterID}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Instagram:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="honorifics"
                value={formData.instagramID}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Facebook:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="name"
                value={formData.facebookID}
                onChange={handleChange}
              />
            </label>
            <label className="block mb-2">
              Linkedin:
              <input
                className="w-full p-2 border rounded-md mt-1"
                type="text"
                name="honorifics"
                value={formData.linkedinID}
                onChange={handleChange}
              />
            </label>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              type="submit"
            >
              Submit
            </button>
          </>
        )}
       
      </form>
    </div>
  );
};

export default Leader;
