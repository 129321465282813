import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaChevronLeft } from 'react-icons/fa6';
import { Input, Select } from 'antd';
import { useDebounce } from 'use-debounce';
import axios from "axios";
const { Option } = Select;
interface EventFormData {
    name: string;
    type: string;
    theme: string;
    city: string;
    host: string;
    duration:number;
    numberParticipants:number;
    participantsId:any;
    nationId: number;
    regionId: number;
    subRegionId: number;
    date: Date | null;
    coverImage: string;
  }
  
interface User {
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}
interface DropdownOption {
    id: number;
    name: string;
  }
  interface UserDropdownOption {
    id: string;
    name: string;
  }
  interface UserListProps {
    users: User[];
    deleteUserProp:any;
}
const SummitEdit: React.FC<UserListProps> = ({users,deleteUserProp}) => {
  const { id } = useParams<{ id: string }>();
  const [fetchedData, setFetchedData] = useState<EventFormData | null>(null);
  const [formData, setFormData] = useState<EventFormData | null>(null);
  const storedUser = localStorage.getItem('user');
  const [usersOptions, setUsersOptions] = useState<any[]>([]);
  const regionData = localStorage.getItem('regions');
  const regionOptions = regionData ? JSON.parse(regionData) : [];
  const subRegionData = localStorage.getItem('subRegions');
  const subRegionOptions = subRegionData ? JSON.parse(subRegionData) : [];
  const nationData = localStorage.getItem('nations');
  const nationalityOptions= nationData ? JSON.parse(nationData) : [];
  const [userFilter, setUserFilter] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [debouncedFilterValue] = useDebounce(filterValue, 100);
  const [massParticipants, setMassParticipants] = useState<string[]>([]);
  const user = storedUser ? JSON.parse(storedUser) as User:null ;
  const [imageURL, setImageURL] = useState<string>('');
  const navigate = useNavigate();
  console.log(userFilter)
  useEffect(() => {
    const fetchActivityData = async () => {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      try {
        const response = await fetch(`https://api.upfafrica.org/admin/activity/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          console.error('Failed to fetch activity data');
          return;
        }
        const responseData = await response.json();
        console.log(userFilter)
      const { primaryTag,duration,numberParticipants,status,participantsId,...filteredData } = responseData.data;
      let newUserFilter:any = [];
if(Array.isArray(participantsId)){
    participantsId.forEach((participant:any) => {
        newUserFilter.push(participant);
    });
}
// Update the userFilter state with the new array
setUserFilter(newUserFilter);

      const updatedData = {
        ...filteredData,
        status:'Pending',
        duration: (duration && !isNaN(duration) && duration !== "") ? duration : 10,
        numberParticipants: (numberParticipants && !isNaN(numberParticipants) && numberParticipants !== "") ? numberParticipants : 10,
        primaryTag: (primaryTag && !isNaN(primaryTag) && primaryTag !== "") ? primaryTag : 10,
      };

      setFetchedData(updatedData);
      setFormData(updatedData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    

    fetchActivityData();
  }, [id]);
 
  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          console.error('Token not found in localStorage');
          return;
        }

        
        if (users) {
          const filteredData = users.filter((item: any) => {
            if (item) {
              const name = item.name ? item.name.toLowerCase() : '';
              return (
                name.includes(debouncedFilterValue.toLowerCase())
              );
            } else {
              return false;
            }
          });
          setUsersOptions(filteredData);
        }
        
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, [debouncedFilterValue]);
  const handleDateChange = (date: Date | null) => {
    setFormData((prevFormData) => ({
      ...prevFormData!,
      date,
    }));
  };
  
  const handleSelectChange = (name: keyof EventFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData!,
      [name]: name === 'nationality' || name === 'userType' || name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    // For non-picture inputs, update form data as usual
    setFormData({
      ...formData!,
      [name]:  name === 'nationality' || name === 'userType' || name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);
  
      // Upload image with robust error handling
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${"e25ca86e136c8772548b88111b46546196e5a2d473e8328497f69a3a21936d3930d4ca4018fa414ab7b4b84dae67d26e0be033ccffd82e6511bca3929204f2326837c520ee51126ee701a6aba146f4abd9ee24d3e9a183e38f065f0bde5f42628978d36e727ade3110d7b64a00bda8ae7616201dc56e0915e4f3920030cdc70e"}`, // Replace with your actual token
          },
        }
      );
  
      if (!response.status || !response.data) {
        throw new Error('Failed to upload image');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastImageUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setImageURL(lastImageUrl);
        console.log('Image uploaded successfully:', imageURL);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Image upload error:', error);
      // Handle error (e.g., display a message to the user)
    }
  };

  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  e.preventDefault();
console.log(userFilter)
  const token = sessionStorage.getItem('token');
  if (!token) {
    console.error('Token not found in localStorage');
    return;
  }
console.log(formData)
  try {
    const response = await fetch(`https://api.upfafrica.org/admin/activity/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ ...formData!, coverImage: imageURL,participantsId:userFilter,})
    });

    if (!response.ok) {
      const Error = await response.json();
      console.error('Failed to submit form', Error);
      return;
    }
    const responseData = await response.json();
    navigate(`/Summits/${id}`);
    console.log(responseData);
  } catch (error) {
    console.error('Error:', error);
  }
};
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setFilterValue(e.target.value);
};
  
  if (!fetchedData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
          <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
      </div>
    );
  }
  const back=()=>{
    navigate(-1)
   }
   const handleUserCheckboxChange = (subName: string) => {
    if (userFilter.includes(subName)) {
        setMassParticipants(userFilter.filter((name) => name !== subName));
    } else {
        setMassParticipants([...userFilter, subName]);
    }
};
   return (
    <>
    <button onClick={back} className='flex flex-row mb-4 hover'>
    <FaChevronLeft className='w-6 h-6' />
     <p className='text-xl font-semibold ml-3'>Back</p>
    </button>
    <form className="max-w mx-auto p-4 " onSubmit={handleSubmit}  style={getTitleStyle()}>
      <label className=" mb-2 flex flex-col">
        Name:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="name"
          value={formData?.name}
          onChange={handleChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        Type:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="type"
          value={formData?.type}
          onChange={handleChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        Theme:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="theme"
          value={formData?.theme}
          onChange={handleChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        Date:
        <DatePicker
          className="w-full p-2 border rounded-md mt-1"
          selected={formData?.date}
          onChange={handleDateChange}
        />
      </label>
      <label className="flex flex-col mb-2">
        host:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="host"
          value={formData?.host}
          onChange={handleChange}
        />
      </label>
     
      <label className="flex flex-col mb-2">
        city:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="city"
          name="city"
          value={formData?.city}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2">
       Participants:
       <Input
                placeholder="Search by name, position, or nationality"
                value={filterValue}
                onChange={handleInputChange}
                className="mb-4 w-64"
            />
       <Select
    mode="multiple"
   placeholder="Select 
   Participants"
   className="w-full h-12"
   onChange={(user) => setUserFilter(user)}
   value={userFilter}
   >
    
     {usersOptions.map(user => (
        <Option key={user.id} value={user.id}>
         
        <div className="flex justify-between items-center">
            <span>{user.name}</span>
            <input
                type="checkbox"
                checked={userFilter.includes(user?.id)}
                onChange={() => handleUserCheckboxChange(user?.id)}
            />
        </div>
    </Option>
     ))}
   </Select>
      </label>
      
      <label className="block mb-2">
        Region:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="regionId"
          value={formData?.regionId}
          onChange={handleSelectChange('regionId')}
        >
          <option value="">Select a region</option>
          {regionOptions.map((region:any) => (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          ))}
        </select>
      </label>
      {/* Your existing form inputs */}
      <label className="block mb-2">
        Sub-Region:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="subRegionId"
          value={formData?.subRegionId}
          onChange={handleSelectChange('subRegionId')}
        >
          <option value="">Select a sub-region</option>
          {subRegionOptions.map((subRegion:any) => (
            <option key={subRegion.id} value={subRegion.id}>
              {subRegion.name}
            </option>
          ))}
        </select>
      </label>
      <label className="block mb-2">
        Nationality:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="nationality"
          value={formData?.nationId}
          onChange={handleSelectChange('nationId')}
        >
          <option value="">Select a nationality</option>
          {nationalityOptions.map((nationality:any) => (
            <option key={nationality.id} value={nationality.id}>
              {nationality.name}
            </option>
          ))}
        </select>
      </label>
     
      <label className="block mb-2">
        Picture:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="file"
          name="coverImage"
          onChange={handleImageChange}
        />
      </label>

      <img src={imageURL||formData?.coverImage} alt="Uploaded" className='w-1/4 h-1/4 object-cover rounded-lg mb-4' />
      <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" type="submit">
        Submit
      </button>
    </form>
    </>
  );
};

export default SummitEdit;
