import React, { useState, ChangeEvent, FormEvent } from 'react';
import {  useNavigate } from "react-router-dom";
interface AmbassadorFormData {
  name: string;
  type: string;
  theme: string;
  city: string;
  host: string;
  nationId:number;
  regionId: number,
  subRegionId: number,
  // picture: null | string;
}
const getTitleStyle = () => ({
  fontFamily: 'Goudy Old Style',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
});
const SummitInput: React.FC = () => {
  const [formData, setFormData] = useState<AmbassadorFormData>({
    name: '',
    type: '',
    city: '',
    theme: '',
    host: '',
    nationId: 1,
    subRegionId: 1,
    regionId: 1,
    // picture: null,
   
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : name === 'nationId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  // const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (typeof reader.result === 'string') {
  //         setFormData({
  //           ...formData,
  //           picture: reader.result,
  //         });
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const navigate = useNavigate();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const token =sessionStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }
  
    try {
      const response = await fetch('https://api.upfafrica.org/admin/activity/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();
      if (!response.ok) {
        console.error('Failed to submit form', responseData.error);
      } else {
        navigate('/Summits')
        console.log(responseData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    <form className="max-w mx-auto p-4 border rounded-md border-gray-300" onSubmit={handleSubmit} >
      <label className="block mb-2 "  style={getTitleStyle()}>
        Name:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2"  style={getTitleStyle()}>
        Type:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="type"
          value={formData.type}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2">
        Theme:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="theme"
          value={formData.theme}
          onChange={handleChange}
        />
      </label>
      
      <label className="block mb-2">
        host:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="host"
          value={formData.host}
          onChange={handleChange}
        />
      </label>
     
      <label className="block mb-2">
        city:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="city"
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
      </label>
      
      
      <label className="block mb-2">
        Nationallty:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="number"
          name="nationId"
          value={formData.nationId}
          onChange={handleChange}
        />
        
      </label>
      <label className="block mb-2">
        RegionId:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="number"
          name="regionId"
          value={formData.regionId}
          onChange={handleChange}
        />
        
      </label>
      <label className="block mb-2">
        SubRegionId:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="number"
          name="subRegionId"
          value={formData.subRegionId}
          onChange={handleChange}
        />
        
      </label>
        {/* <label className="block mb-2">
        Picture:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="file"
          name="picture"
          accept="image/*"
          onChange={handleImageChange}
        />
      </label> */}
      <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" type="submit">
        Submit
      </button>
    </form>
  );
};

export default SummitInput;
