import React, { createContext, useContext, useState, useEffect } from 'react';
const SummitsContext = createContext();

export const useSummits = () => {
  return useContext(SummitsContext);
};

export const SummitsProvider = ({ children }) => {
  const [summitsCount, setSummitsCount] = useState(0);
  const[allSummit,setAllSummit]=useState([])
  const[summit,setSummit]=useState([])
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(false);

  useEffect(() => {
    const notificationsEnabled = localStorage.getItem('notificationsEnabled') === 'true';
    setIsNotificationsEnabled(notificationsEnabled);
    if ('Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  const fetchSummits = async () => {
    try {
      const response = await fetch('https://api.upfafrica.org/admin/activity/list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({
          query: { isDeleted: false },
          options: {
            select: ['id', 'name', 'coverImage', 'host', 'participantsId', 'date', 'theme', 'status', 'nationId', 'addedBy', 'subRegionId', 'isDeleted'],
            paginate: 3000
          },
          isCountOnly: false
        }),
      });

      if (!response.ok) throw new Error('Failed to fetch summits');

      const data = await response.json();
      setAllSummit(data.data.data)
      const approvedSummits = data.data.data.filter(summit => summit.status === 'Approved');
      setSummit(approvedSummits )
      const pendingSummitsCount = data.data.data.filter(summit => summit.status === 'Pending').length;
      setSummitsCount(pendingSummitsCount);

      // Update the service worker with the new count
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'UPDATE_PENDING_COUNT',
          count: pendingSummitsCount
        });
      }

      // Trigger local notification if enabled
      if (isNotificationsEnabled && pendingSummitsCount > 0) {
        triggerNotification(pendingSummitsCount);
      }
    } catch (error) {
      console.error('Error fetching summits:', error);
    }
  };

  const triggerNotification = (count) => {
    if ('Notification' in window && Notification.permission === 'granted') {
      new Notification('Summits Awaiting Approval', {
        body: `You have ${count} summits awaiting approval.`,
      });
    }
  };

  const toggleNotifications = () => {
    const newState = !isNotificationsEnabled;
    setIsNotificationsEnabled(newState);
    localStorage.setItem('notificationsEnabled', newState.toString());
  };

  useEffect(() => {
    fetchSummits();
    const intervalId = setInterval(fetchSummits, 1 * 60 * 1000); 
    return () => clearInterval(intervalId);
  }, []);

  return (
    <SummitsContext.Provider value={{
      allSummit,
      summit,
      summitsCount,
      isNotificationsEnabled,
      toggleNotifications
    }}>
      {children}
    </SummitsContext.Provider>
  );
};