import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard/dashboard';
import SRNorth from './components/pages/subRegions/srNorth';
import ProfileSettings from './components/pages/Login/profile'
import SRWest from './components/pages/subRegions/srWest';
import SrCentral from './components/pages/subRegions/sr-central';
import SrSouth from './components/pages/subRegions/srSouth';
import SummitEdit from './components/pages/summits/summitEdit';
import ChangePassword from './components/pages/Login/changepassword';
import ProfileEdit from './components/pages/Login/ProfileEdit';
import Leader from './components/pages/leader&Profile/LeadersCreation';
import SrEast from './components/pages/subRegions/srEast';
import MainPage from './components/pages/Dashboard/mainPage'; 
import Login from './components/pages/Login/login';
import Ambassador from './components/pages/AmbassadorsInput/AmbassadorsCreation';
import EditAmbassador from './components/pages/Editing/EditPage';
import Contact from './components/pages/contacts/ContactInput'
import Ambassador0 from './components/pages/AmbassadorsInput/mainambassador';
import { SummitType,SummitsDetail } from './components/pages/summits/summits';
import { SummitApproval,SummitsDetailApproval } from './components/pages/summits/approval'
import { IndividualDetails, LeadershipGrid } from "./components/pages/leader&Profile/leadershipPage&Profiles";
import Calendar from './components/pages/SummitReminders/Calendar';
import Nation from './components/pages/Nations/nationsPage';
import Contacts from './components/pages/contacts/Contacts';
import EventForm from './components/pages/summits/EventForm';
import Settings from './components/pages/Settings/settings';
import Donation from './components/pages/Donation/Donations';
import   {Associations,AssociationDetails} from './components/pages/Association/association';
import SummitsInput from './components/pages/summits/summitsinput';
import ServerErrorPage from './components/pages/ErrorPages/serverError';
// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false, errorStatus: null };
//   }

//   static getDerivedStateFromError(error) {
//     console.error(error);
//     if (error.response) {
//       const status = error.response.status;
//       if (status >= 400 && status < 500) {
//         return { hasError: true, errorStatus: status };
//       } else if (status >= 500 && status < 600) {
//         return { hasError: true, errorStatus: status };
//       }
//     }
//     return { hasError: true, errorStatus: 500 };
//   }

//   render() {
//     if (this.state.hasError) {
//       const errorMessages = {
//         400: '400 Bad Request',
//         401: '401 Unauthorized',
//         403: '403 Forbidden',
//         404: '404 Not Found',
//         500: '500 Internal Server Error',
//         502: '502 Bad Gateway',
//         503: '503 Service Unavailable',
//         504: '504 Gateway Timeout',
//         default: 'An error occurred',
//       };
//       const errorMessage =
//         errorMessages[this.state.errorStatus] ||
//         errorMessages['default'];
//       return <ServerErrorPage error={errorMessage} />;
//     }

//     return this.props.children;
//   }
// }


const App = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      }).catch(error => {
        console.log('Service Worker registration failed:', error);
      });
    });
  }

  return (
    <Router >
      {/* <ErrorBoundary> */}
      <Routes>
       <Route path="/" element={<Dashboard><MainPage /></Dashboard>} />
       <Route path='/nation/:id'element={<Dashboard><Nation/></Dashboard>} />
        <Route path="/sr-west" element={<Dashboard><SRWest /></Dashboard>} />
        <Route path="/sr-central" element={<Dashboard><SrCentral /></Dashboard>} />
       <Route path="/sr-east" element={<Dashboard><SrEast /></Dashboard>} />
        <Route path="/sr-north" element={<Dashboard><SRNorth /></Dashboard>} />
         <Route path="/sr-south" element={<Dashboard><SrSouth /></Dashboard>} />
        <Route path="/association" element={<Dashboard><Associations associates={Associations} /></Dashboard>} />
        {/* Add other conditions as needed */}
        <Route path="/login" element={<Login />} />
        <Route path="/association/:primaryTag" element={<Dashboard><AssociationDetails/></Dashboard>} />
        <Route path="/Ambassador-info" element={<Dashboard><Ambassador0 /></Dashboard>} />
        <Route path="/Ambassadorinput" element={<Dashboard><Ambassador /></Dashboard>} />
        <Route path="/Leadershipinput" element={<Dashboard><Leader /></Dashboard>} />
        <Route path="/Contactinput" element={<Dashboard><Contact /></Dashboard>} />
        <Route path="/Leadership" element={<Dashboard><LeadershipGrid /></Dashboard>} />
        <Route path="/Contacts" element={<Dashboard><Contacts/></Dashboard>} />
        <Route path="/individual/:id" element={<Dashboard><IndividualDetails /></Dashboard>} />
        <Route path="/settings" element={<Dashboard><Settings /></Dashboard>} />
        <Route path="/passwordchange" element={<Dashboard><ChangePassword /></Dashboard>} />
        <Route path="/calendar" element={<Dashboard><Calendar /></Dashboard>} />
        <Route path="/profile" element={<Dashboard><ProfileSettings/></Dashboard>} />
        <Route path="/donation" element={<Dashboard><Donation /></Dashboard>} />
        
        <Route path="/summitsinput" element={<Dashboard><SummitsInput /></Dashboard>} />
        <Route path="/Summits" element={<Dashboard><SummitType /></Dashboard>} />
        <Route path="/EditAmbassador/:id" element={<Dashboard><EditAmbassador/></Dashboard>} />
        <Route path="/profileEdit/:id" element={<Dashboard><ProfileEdit/></Dashboard>} />
        <Route path='/approval' element={<Dashboard><SummitApproval/></Dashboard>}/>
        <Route path='/approval/:id' element={<Dashboard><SummitsDetailApproval/></Dashboard>}/>
        <Route path="/Summits/:id" element={<Dashboard><SummitsDetail /></Dashboard>} />
        <Route path="/SummitEdit/:id" element={<Dashboard><SummitEdit/></Dashboard>} />
        <Route
          path="/add-event"
          element={<Dashboard><EventForm onAddEvent={(event) => { console.log(event); }} /></Dashboard>}
        />
        {/* Default route for every user */}
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/error" element={<Dashboard><ServerErrorPage/></Dashboard>} />
        <Route render={() => <div>Not Found</div>} />
      </Routes>
      {/* </ErrorBoundary> */}
    </Router>
  );
};

export default App;