import React, { useEffect, useState } from 'react';
import Sidebar from './sideBar';
import Navbar from './navbar';
import { notification} from 'antd';
import { MdDashboard, MdEventRepeat, MdConnectWithoutContact } from "react-icons/md";
import { TiGroup } from "react-icons/ti";
import { BiWorld, BiSolidDonateHeart } from "react-icons/bi";
import { IoPerson, IoSettings } from "react-icons/io5";
import { FaRegImage, FaCalendarAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSummits } from '../../contextApis/SummitProvider';
import { FadeLoader } from 'react-spinners';

interface User {
    userType: number;
    subRegionId: number;
    regionId: number;
    nationality: number;
}
interface DashboardChildProps {
    regionOptions: any[];
    subRegionOptions: any[];
    nationalityOptions: any[];
}

interface MenuItem {
    icon: JSX.Element;
    text: JSX.Element | string;
    to: string;
    colorClass: string;
}
interface DashboardProps {
    children: React.ReactElement | React.ReactElement[];
}

const Dashboard: React.FC<DashboardProps> = ({ children }) => {
    const { summitsCount } = useSummits();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [selectedMenu, setSelectedMenu] = useState<any>(null);
    const [users, setUsers] = useState<any[]>([]);
    const storedUser = localStorage.getItem('user');
    const User = storedUser ? JSON.parse(storedUser) as User : null;
    const[loading,setLoading]=useState<boolean>(true)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    
    const GetAllUsers = async () => {
        try {
            const apiUrl = 'https://api.upfafrica.org/admin/user/list';
            const token = sessionStorage.getItem('token');
    
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    query: {},
                    options: {
                        select: [
                            'id', 'name', 'regionId', 'subRegionId', 'ambassadorStatus', 'email',
                            'upfPosition', 'nationality', 'primaryTag', 'leadershipStatus',
                            'username', 'picture'
                        ],
                        paginate: 500000,
                    },
                    isCountOnly: false,
                }),
            });
    
            if (!response.ok) {
                console.error('Request failed:', response.status, response.statusText);
                throw new Error('Request failed.');
            }
    
            const userData = await response.json();
            setUsers(userData.data.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            return [];
        }
        finally{
            setLoading(false)
        }
    };
    
    const dataFetch = async () => { 
        try {
            const [regionResponse, subRegionResponse, nationalityResponse] = await Promise.all([
                fetch('https://api.upfafrica.org/admin/region/list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        query: {},
                        options: {
                            select: ['id', 'name'],
                            paginate: 166,
                        },
                        isCountOnly: false,
                    }),
                }),
                fetch('https://api.upfafrica.org/admin/subregion/list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        query: {},
                        options: {
                            select: ['id', 'name'],
                            paginate: 166,
                        },
                        isCountOnly: false,
                    }),
                }),
                fetch('https://api.upfafrica.org/admin/nation/list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        query: {},
                        options: {
                            select: ['id', 'name'],
                            paginate: 166,
                        },
                        isCountOnly: false,
                    }),
                }),
            ]);
    
            const [regionData, subRegionData, nationalityData] = await Promise.all([
                regionResponse.json(),
                subRegionResponse.json(),
                nationalityResponse.json(),
            ]);
    
            if (regionData?.data) {
               localStorage.setItem ("regions",JSON.stringify(regionData.data.data))
            }
            if (subRegionData?.data) {
                localStorage.setItem("subRegions",JSON.stringify(subRegionData.data.data))
            }
            if (nationalityData?.data) {
                localStorage.setItem("nations",JSON.stringify(nationalityData.data.data))
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const deleteUserProp = async (userId: string) => {
        try {
            const apiUrl = `https://api.upfafrica.org/admin/user/delete/${userId}`;
            const token = sessionStorage.getItem('token');
            
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                console.error('Delete request failed:', response.status, response.statusText);
                throw new Error('Failed to delete user.');
            }
    
            // Remove user from the local users state after successful deletion
            setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
            console.log('User deleted successfully');
            notification.error({
                message: 'User deleted successfully',
                description: 'The User has Been Deleted',
                duration: 3,
            });
            GetAllUsers()
        } catch (error) {
            console.error('Error deleting user:', error);
            notification.error({
                message: 'Error Deleting User',
                description: 'An error occurred while deleting the user.',
                duration: 3,
            });
        }
    };
    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else {
            dataFetch();
            GetAllUsers()
        }
    }, [token, navigate]);
    const menuItems: any[] = [
        ...(((User?.userType === 8)||(User?.userType === 2)||(User?.userType === 7)||(User?.userType === 6)||(User?.userType === 3)||(User?.userType === 5))? [{ icon: <MdDashboard className="hover:bg-green-500" />, text: t('Dashboard'), to: '/', colorClass: 'icon h2' }] : []),
        { icon: <TiGroup className="hover:bg-green-500" />, text: t('Association'), to: '/Association', colorClass: 'icon' },
        ...((User?.userType === 8)||(User?.userType === 3)||(User?.userType === 2)? [{ icon: <MdEventRepeat />, text: (<>Activity Approval <div className='rounded-full bg-red-500 h-6 w-6  text-red-900 flex justify-center ml-4  '><p >{summitsCount}</p></div></>), to: '/approval', colorClass: 'icon' }] : []),
        { icon: <BiWorld />, text: t('Activities'), to: '/Summits', colorClass: 'icon' },
        { icon: <MdConnectWithoutContact />, text: t('Contacts'), to: '/Contacts', colorClass: 'icon' },
        { icon: <IoPerson />, text: t('Ambassadors for Peace'), to: '/Ambassador-info', colorClass: 'icon' },
        { icon: <FaRegImage />, text: t('Leadership'), to: '/Leadership', colorClass: 'icon' },
        { icon: <FaCalendarAlt />, text: t('Calendar'), to: '/calendar', colorClass: 'icon' },
        { icon: <BiSolidDonateHeart />, text: t('Donation'), to: '/error', colorClass: 'icon' },
        { icon: <IoSettings />, text: 'Settings', to: '/error', colorClass: 'icon' },
    ];
    return (
        <div className="flex">
            <div className=' lg:block hidden'>
            <Sidebar setSelectedMenu={setSelectedMenu} isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} menuItems={menuItems}/>
            </div>
            <div className="flex flex-col lg:w-[100%]">
                <Navbar setSelectedMenu={setSelectedMenu} menuItems={menuItems} isSidebarOpen={isSidebarOpen}/>
                
               {loading? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
    ):(
        <div className="content">
        {React.Children.map(children, (child) =>
                           React.cloneElement(child, { users, deleteUserProp })
                       )}
                    </div> 
    ) }
            </div>
        </div>
    );
};

export default Dashboard;
