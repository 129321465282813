import React, {  useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { CalendarOutlined, EnvironmentOutlined, TeamOutlined, EditOutlined } from '@ant-design/icons';
import 'swiper/swiper-bundle.css';
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { Button, Input, Modal, Pagination as AntPagination, Select, notification,Tabs } from 'antd';
import { t } from "i18next";
import User from "../images/conference.jpg";
import { FadeLoader } from "react-spinners";
import { FcDeleteDatabase } from "react-icons/fc";
import { useSummits } from '../../contextApis/SummitProvider';
import { useDebounce } from "use-debounce";
const { TabPane } = Tabs;
const { Option } = Select;
interface EventFormData {
    name: boolean;
  }
  interface Usr {
    id: number;
    name: string;
  }
  

  interface Participant {
    summitId: number;
    participantNames: string[];
  }
  
interface User {
  id:string
  userType: number;
  subRegionId:number;
  nationId:number
}
interface UserListProps {
  users: User[];
}

interface Summit {
  id: string;
  name: string;
  coverImage: string;
  host: string;
  date: string;
  theme: string;
  status: 'Pending' | 'Approved' | 'Edited' | 'Declined';
  nationId: string;
  addedBy: string;
  participantsId: number[];
  subRegionId: any;
  isDeleted: boolean;
}

const SummitApproval: React.FC = () => {
  const navigate = useNavigate();
  const { allSummit } = useSummits();
  const [filteredSummits, setFilteredSummits] = useState<Summit[]>([]);
  const [timeFilter, setTimeFilter] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('all');
  const [loading, setLoading] = useState(true);
  const user: User | null = JSON.parse(localStorage.getItem('user') || 'null');
  const nations = JSON.parse(localStorage.getItem('nations') || '[]');

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 20000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    filterSummits();
  }, [timeFilter, allSummit, activeTab]);

  const filterSummits = () => {
    if (!user) return;

    const currentDate = new Date();
    const filteredData = allSummit.filter((summit: Summit) => {
      const summitDate = new Date(summit.date);
      const subRegionCheck = summit.subRegionId === user.subRegionId;
      const createdCheck = summit.addedBy === user.id;
      
      const timeFilterCheck = 
        (timeFilter === 'Current Events' && summitDate.toDateString() === currentDate.toDateString()) ||
        (timeFilter === 'Past Events' && summitDate < currentDate) ||
        (timeFilter === 'Future Events' && summitDate > currentDate) ||
        !timeFilter;

      const roleFilterCheck = (() => {
        switch (user.userType) {
          case 8: return summit.status === 'Pending' || createdCheck;
          case 2: return summit.status === 'Pending' && subRegionCheck;
          case 3: return (summit.status === 'Edited' || summit.status === 'Pending') && createdCheck;
          default: return createdCheck && summit.status === 'Approved';
        }
      })();

      const tabFilterCheck = 
        activeTab === 'all' || 
        (activeTab === 'pending' && summit.status === 'Pending') ||
        (activeTab === 'approved' && summit.status === 'Approved') ||
        (activeTab === 'edited' && summit.status === 'Edited') ||
        (activeTab === 'declined' && summit.status === 'Declined');

      return timeFilterCheck && roleFilterCheck && tabFilterCheck && !summit.isDeleted;
    });

    const sortedData = filteredData.sort((a: Summit, b: Summit) => 
      a.status === 'Pending' ? -1 : b.status === 'Pending' ? 1 : 0
    );

    setFilteredSummits(sortedData);
  };

  const getNationName = (id: string) => nations.find((n: any) => n.id === id)?.name || 'No Nation';

  const statusStyles = {
    Approved: 'bg-green-400 text-green-900',
    Edited: 'bg-purple-400 text-purple-900',
    Pending: 'bg-yellow-400 text-yellow-900',
    Declined: 'bg-red-400 text-red-900',
  };

  const renderSummitCard = (summit: Summit) => (
    <div key={summit.id} className="flex flex-col justify-between bg-white border rounded-lg shadow-lg overflow-hidden border-gray-200">
      <div>
        <img
          src={summit.coverImage ? `https://fm.upfafrica.org${summit.coverImage}` : '/path/to/default/image.jpg'}
          alt={summit.name}
          className="h-48 w-full object-cover"
        />
        <div className="p-4">
          <div className="flex justify-between items-start mb-2">
            <Link to={`/approval/${summit.id}`} className="text-lg font-semibold hover:underline text-black">{summit.name || 'No Name'}</Link>
            <span className="text-sm font-medium bg-gray-100 text-black rounded-full px-3 py-1">{getNationName(summit.nationId)}</span>
          </div>
          <p className="text-sm text-gray-600 mb-2">Theme: {summit.theme}</p>
          <Link to={`/approval/${summit.id}`} className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${statusStyles[summit.status]}`}>
            {summit.status}
          </Link>
        </div>
      </div>
    </div>
  );

  const renderNoDataMessage = () => (
    <div className="flex flex-col items-center justify-center h-64">
      <FcDeleteDatabase size={64} className="mb-4" />
      <p className="text-xl font-semibold text-gray-600">No summits found</p>
      <p className="text-gray-500">There are no summits available in this category at the moment.</p>
    </div>
  );


  if (filteredSummits.length === 0 ) {
    return (
      <div className="w-full p-6">
        <div className="flex justify-between mb-6">
          <Select
            defaultValue=""
            className="w-48"
            onChange={(value) => setTimeFilter(value)}
          >
            <Option value="">All Events</Option>
            <Option value="Current Events">Current Events</Option>
            <Option value="Future Events">Future Events</Option>
            <Option value="Past Events">Past Events</Option>
          </Select>
          {user && [8, 6, 7].includes(user.userType) && (
            <button
              onClick={() => navigate('/add-event')}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
            >
              + Add Event
            </button>
          )}
        </div>
        <Tabs defaultActiveKey="all" onChange={(key) => setActiveTab(key)}>
        {['all', 'pending', 'approved', 'edited', 'declined'].map((tab) => (
          <TabPane tab={tab.charAt(0).toUpperCase() + tab.slice(1)} key={tab}>
        {renderNoDataMessage()}
        </TabPane>
        ))}
      </Tabs>
      </div>
    );
  }

  return (
    <div className="w-full p-6">
      <div className="flex justify-between mb-6">
        <Select
          defaultValue=""
          className="w-48"
          onChange={(value) => setTimeFilter(value)}
        >
          <Option value="">All Events</Option>
          <Option value="Current Events">Current Events</Option>
          <Option value="Future Events">Future Events</Option>
          <Option value="Past Events">Past Events</Option>
        </Select>
        {user && [8, 6, 7].includes(user.userType) && (
          <button
            onClick={() => navigate('/add-event')}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
          >
            + Add Event
          </button>
        )}
      </div>
      <Tabs defaultActiveKey="all" onChange={(key) => setActiveTab(key)}>
        {['all', 'pending', 'approved', 'edited', 'declined'].map((tab) => (
          <TabPane tab={tab.charAt(0).toUpperCase() + tab.slice(1)} key={tab}>
            {filteredSummits.filter(summit => 
              tab === 'all' || summit.status.toLowerCase() === tab
            ).length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {filteredSummits
                  .filter(summit => tab === 'all' || summit.status.toLowerCase() === tab)
                  .map(renderSummitCard)}
              </div>
            ) : (
              renderNoDataMessage()
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
const SummitsDetailApproval: React.FC<UserListProps> = ({users})  => {
  const { id } = useParams();
  const [summit, setSummit] = useState<any>(null);
  const navigate = useNavigate();
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterValue, setFilterValue] = useState('');
  const [debouncedFilterValue] = useDebounce(filterValue, 200);
  const[userList,setUserList]=useState<any[]>([])
  const [formData, setFormData] = useState<EventFormData | null>(null);
  const [comment, setComment] = useState<string>('');
  const[participants,setParticipants]=useState<any[]>([])
  const nationData = localStorage.getItem('nations');
  const nationList = nationData ? JSON.parse(nationData) : [];
  const [commentsArray, setCommentsArray] = useState<string[]>([]);
  const darkMode=useDarkMode()
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User : null;
  const subRegionCheck = (user?.userType === 2 || user?.userType === 8);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const handleSave = () => {
    setIsModalVisible(false);
    handleApprove(summit.id,'Edited',comment)
    navigate('/approval')
  };
  useEffect(() => {
    const getSummitDetails = async () => {
      console.log(id)
      try {
        const apiUrl = `https://api.upfafrica.org/admin/activity/${id}`;
        const token = sessionStorage.getItem('token');

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          console.error('Request failed:', response.status, response.statusText);
          throw new Error('Request failed.');
        }

        const summitData = await response.json();
        console.log('summitData:', summitData);
        setSummit(summitData.data);
        console.log("1Summit",summit)
 
        if (users) {
          const filteredData = users.filter((item: any) => {
            return (
              (item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
              (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase())) ||
              (item.nationality && getNationNameById(item.nationality).toLowerCase().includes(debouncedFilterValue.toLowerCase()))
            );
          });
  
          setUserList(filteredData);
          if (filteredData.length > 0 && summit && Array.isArray(summit.participantsId)) {
            const participant = summit.participantsId.map((id: any) => {
              const user = filteredData.find((user:any) => user.id === id);
              return user ? user : null;
            }).filter((name: any) => name !== null) as string[]; // Remove null values and cast to string[]
  
            setParticipants(participant);
          }
        } else {
          setUserList([]);
        setParticipants([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    if (id) {
      getSummitDetails();
    }
  }, [id, debouncedFilterValue,summit, userList]);
  // useEffect(() => {
    
  // }, [summit, userList]);
  console.log(participants)
  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
  if (!summit) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FadeLoader color="#367fd6" className='w-96 h-96' />
    </div>
    );
  }
  const handleApprove = async (id:any,action:string,editRequest:any) => {
    // action.preventDefault();
  
    const token = sessionStorage.getItem('token');
    if (!token) {
      console.error('Token not found in sessionStorage');
      return;
    }
  
    try {
      const response = await fetch(`https://api.upfafrica.org/admin/activity/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({...formData!,status:action,editRequest:commentsArray }),
      });
  
      if (!response.ok) {
        const Error = await response.json();
        console.error('Failed to submit form', Error);
        return;
      }
      const responseData = await response.json();
      navigate(`/approval`);
      console.log(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const AddComment = () => {
    if (comment && comment.trim()) { 
        commentsArray.push(comment);
    } else {
        console.error("Comment cannot be empty.");
    }
    setComment('')
};
const Cancel=()=>{
  setIsModalVisible(false)
  setCommentsArray([])
}
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setFilterValue(e.target.value);
  setCurrentPage(1); 
};
const getNationNameById = (id: number) => {
  const nation = nationList.find((nation:any) => nation.id === id);
  return nation ? nation.name : 'Unknown';
};
const paginatedparticipantsData = participants && participants.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    console.log("gallery",summit.gallery)
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="relative h-96">
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation
              loop={true}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              className="h-full"
            >
              <SwiperSlide>
                <img
                  src={`https://fm.upfafrica.org${summit.coverImage}`}
                  alt={summit.name}
                  className="w-full h-full object-cover"
                />
              </SwiperSlide>
              {summit?.gallery && summit.gallery.map((image: string, index: number) => (
                <SwiperSlide key={index}>
                  <img
                    src={`https://fm.upfafrica.org${image}`}
                    alt={`Gallery image ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="absolute inset-0 bg-black  bg-opacity-50 flex items-end">
              <div className="p-6 text-white">
                <h1 className="text-4xl font-bold mb-2">{summit.name}</h1>
                <p className="text-xl">{summit.host}</p>
              </div>
            </div>
          </div>
  
          <div className="p-6">
            <div className="flex flex-wrap gap-6 text-gray-600 mb-6">
              <div className="flex items-center">
                <CalendarOutlined className="mr-2" />
                {new Date(summit.date).toLocaleDateString()}
              </div>
              <div className="flex items-center">
                <EnvironmentOutlined className="mr-2" />
                {summit.city}
              </div>
              <div className="flex items-center">
                <TeamOutlined className="mr-2" />
                {participants.length} Participants
              </div>
            </div>
  
            <div className="mb-8  text-black">
              <h2 className="text-2xl font-semibold mb-4" style={getTitleStyle()}>Description</h2>
              <div className="prose max-w-none">
                {isDescriptionExpanded && summit.description? summit.description :summit.description && summit.description.slice(0, 250)}
                {summit.description && summit.description.length > 250 && (
                  <button 
                    onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)} 
                    className="text-blue-500 hover:text-blue-700 font-semibold mt-2 block"
                  >
                    {isDescriptionExpanded ? 'Show Less' : 'Read More'}
                  </button>
                )}
              </div>
            </div>
  
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4" style={getTitleStyle()}>Participants</h2>
              <Input
                placeholder="Search by name, position, or nationality"
                value={filterValue}
                onChange={handleInputChange}
                className="mb-4"
              />
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Position</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nationality</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200  text-black">
                    {paginatedparticipantsData.length > 0 ? paginatedparticipantsData.map((participant, index) => (
                      <tr key={participant.id}>
                        <td className="px-6 py-4 whitespace-nowrap">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{participant.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{participant.position || participant.upfPosition}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{participant.nationality && getNationNameById(participant.nationality)}</td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan={4} className="px-6 py-4 text-center">No participants found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <AntPagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={participants.length}
                onChange={handlePageChange}
                className="mt-4 text-center"
              />
            </div>
  
            {summit?.status === 'Edited' && (
              <div className="mb-8 bg-gray-100 rounded-lg p-4">
                <h3 className="text-xl font-semibold mb-2">Edit Requests:</h3>
                <ul className="list-disc pl-5">
                  {summit.editRequest && Array.isArray(summit.editRequest) ? (
                    summit.editRequest.map((feature: string, index: number) => (
                      <li key={index} className="mb-1">{feature}</li>
                    ))
                  ) : (
                    <li>No edit requests available</li>
                  )}
                </ul>
              </div>
            )}
  
            <div className="flex flex-wrap gap-4">
              {summit?.status === 'Pending' && subRegionCheck && (
                <>
                  <Button
                    type="primary"
                    className="bg-green-300 hover:bg-green-400 text-green-700"
                    onClick={() => handleApprove(summit.id, 'Approved', '')}
                  >
                    Approve
                  </Button>
                  <Button
                    className="bg-red-500 hover:bg-red-600 text-white"
                    onClick={() => handleApprove(summit.id, 'Declined', '')}
                  >
                    Decline
                  </Button>
                  <Button
                    className="bg-yellow-500 hover:bg-yellow-600 text-white"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Request Edit
                  </Button>
                </>
              )}
              {(summit?.status === 'Edited' || summit?.status === 'Approved' || summit?.addedBy === user?.id) && (
                <Button
                  className="bg-blue-300 hover:bg-blue-400 text-blue-700"
                  onClick={() => navigate(`/SummitEdit/${summit.id}`)}
                >
                  <EditOutlined /> Edit
                </Button>
              )}
              {summit?.status === 'Edited' && (
                <Button
                  className="bg-green-300 hover:bg-green-400 text-green-700"
                  onClick={() => handleApprove(summit.id, 'Pending', '')}
                >
                  Re-submit
                </Button>
              )}
            </div>
          </div>
        </div>
  
        <Modal
          title="Edit Request"
          visible={isModalVisible}
          onCancel={Cancel}
          footer={[
            <Button key="cancel" onClick={Cancel}>
              Cancel
            </Button>,
            <Button  
              className="bg-blue-400 text-blue-900"
              onClick={AddComment}
            >
              Add Comment
            </Button>,
            <Button 
              key="save" 
              type="primary" 
              onClick={handleSave} 
              style={{ backgroundColor: '#FF0000', color: 'white' }}
            >
              Send
            </Button>,
          ]}
        >
          <TextArea 
            placeholder="Comment" 
            value={comment} 
            onChange={(e) => setComment(e.target.value)} 
            rows={4}
            className="mb-4"
          />
          {commentsArray.length > 0 && (
            <div>
              <h4 className="font-semibold mb-2">Added Comments:</h4>
              <ul className="list-disc pl-5">
                {commentsArray.map((c, index) => (
                  <li key={index}>{c}</li>
                ))}
              </ul>
            </div>
          )}
        </Modal>
      </div>
    );
};
export { SummitApproval, SummitsDetailApproval };
