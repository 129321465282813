import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from 'recharts';
import { useNavigate } from 'react-router-dom';

const NationalityChart = ({ data, nationList, darkMode, t }) => {
  const navigate = useNavigate();

  const formatChartData = () => {
    const nationalityCounts = {};
    data.forEach((user) => {
      if (user.nationality) {
        nationalityCounts[user.nationality] = (nationalityCounts[user.nationality] || 0) + 1;
      }
    });

    const colors = {
      1: '#b87333',
      2: '#3366cc',
      3: '#dc3912',
      4: '#ff9900',
      5: '#109618'
    };

    return Object.entries(nationalityCounts).map(([nationalityId, count]) => {
      const nation = nationList.find(n => n.id === parseInt(nationalityId));
      return {
        name: t(nation?.name || 'Unknown'),
        value: count,
        color: colors[nationalityId] || '#3366cc',
        id: parseInt(nationalityId)
      };
    });
  };

  const handleBarClick = (data) => {
    if (data && data.id) {
      navigate(`/nation/${data.id}`);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: darkMode ? '#333' : '#fff',
            color: darkMode ? '#fff' : '#000',
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <p>{`${payload[0].payload.name}: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full h-[400px]">
      <ResponsiveContainer>
        <BarChart
          data={formatChartData()}
          margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke={darkMode ? '#555' : '#ccc'}
          />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            height={80}
            tick={{ fill: darkMode ? '#fff' : '#000' }}
          />
          <YAxis 
            tick={{ fill: darkMode ? '#fff' : '#000' }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            onClick={handleBarClick}
            cursor="pointer"
            className="hover:opacity-80"
          >
            {formatChartData().map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NationalityChart;
