import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

interface LoginUser {
    data: {
        token: string;
        [key: string]: any;
    };
    id: number;
    name: string;
    email: string;
}

const loginUser = async (email: string, password: string): Promise<LoginUser | any> => {
    try {
        const apiUrl = 'https://api.upfafrica.org/admin/auth/login';
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: email,
                password: password,
            }),
        });

        if (!response.ok) {
            console.error('Login failed:', response.status, response.statusText);
            throw new Error('Login failed.');
        }

        const user = await response.json();

        console.log('User logged in:', user);
        if (user?.data && user.data.token) {
            console.log('User data:', user.data);
            window.localStorage.setItem("user", JSON.stringify(user.data));
            window.sessionStorage.setItem("token", user.data.token);
            return user;
        } else {
            console.error('Invalid user data or missing token:', user);
            throw new Error('Invalid user data or missing token.');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        loginUser(email, password)
            .then((user: LoginUser) => {
                if (user?.data && user.data.token) {
                    navigate('/');
                } else {
                    notification.error({
                        message: 'Login Failed',
                        description: 'Email or password is incorrect. Please try again.',
                    });
                }
            })
            .catch(() => {
                notification.error({
                    message: 'Login Failed',
                    description: 'An error occurred. Please try again.',
                });
            });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    return (
        <div className="min-h-screen text-gray-900 flex items-center justify-center" style={{ background: '#3380B0' }}>
            <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center">
                <div className="p-6 sm:p-12">
                    <div className="mt-1 flex flex-col items-center">
                        <div className="w-full text-center flex-1 mb-2" style={{ color: '#384D7A', fontFamily: 'Trajan Pro', fontSize: '40px', fontStyle: 'normal', lineHeight: 'normal' }}>
                            <h2>SIGN IN</h2>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mx-auto max-w-xs">
                                <input
                                    className="w-full px-8 py-4 font-medium border placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white" style={{ borderColor: '#0072BC', borderRadius: '12px' }}
                                    type="text"
                                    name="email"
                                    placeholder="Username"
                                    onChange={handleChange}
                                    value={email}
                                    required
                                />
                                <input
                                    className="w-full px-8 py-4 font-medium border placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5" style={{ borderColor: '#0072BC', borderRadius: '12px' }}
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    value={password}
                                    required
                                />
                            </div>
                            <button
                                className="mt-5 tracking-wide justify-center text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex" style={{ background: '#0072BC', fontFamily: 'Trajan Pro', fontSize: '25px' }}
                            >
                                <span className="ml-3">Sign In</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
