// src/Calendar.tsx
import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay } from 'date-fns';
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FadeLoader } from 'react-spinners';
import { useSummits } from '../../contextApis/SummitProvider';
interface Event {
  id: any;
  date: Date;
  name: string;
  status:string
}

const Calendar: React.FC = () => {
  const { allSummit } = useSummits();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { darkMode } = useDarkMode();
  
const formattedEvents:Event[] = allSummit.map((event: any) => ({
        id:event.id,
        date: new Date(event.date),
        name: event.name,
        status:event.status
  }));
  const currentMonth = new Date();
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = '';

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, 'd');
      days.push(
        <div
          className={`col flex flex-col items-center justify-center border p-2 min-h-24  w-full  ${
            !isSameMonth(day, monthStart) ? 'bg-gray-600' : isSameDay(day, new Date()) ? 'bg-blue-500' : ''
          } `}
          key={day.toString()}
        >
          <span className="text-sm">{formattedDate}</span>
          <div className="mt-1 text-xs">
          {formattedEvents
  .filter((event:any) => isSameDay(event.date, day) && event.status==='Approved')  
  .map((event:any, id:any) =>{
    const eventDate=new Date(event.date).setHours(0, 0, 0, 0)
    const currentDate=new Date().setHours(0, 0, 0, 0)
    const pastEvent=eventDate<currentDate
    const presentEvent=eventDate===currentDate
    const futureEvent=eventDate>currentDate
    let statusColor=''
    if(pastEvent){
      statusColor='bg-yellow-500'
    }
    else if(presentEvent){
      statusColor='bg-green-500'
    }
    else if(futureEvent){
      statusColor='bg-red-500'
    }
     return(
    // <Link to={`/Summits/${event.id}`}>
    <div key={event.id} className={`${statusColor} rounded p-1 mt-1 `}>
     
        {event.name}
     
    </div>
    // </Link>
  )})}
          </div>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className="row flex" key={day.toString()}>
        {days}
      </div>
    );
    days = [];
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="calendar flex flex-col justify-center items-center bg-white shadow-lg p-6 ml-6 rounded-lg w-f containerrr mt-9">
      <div className="header mb-4 text-lg font-semibold">
        {format(currentMonth, 'MMMM yyyy')}
      </div>
      <div className="body w-full">
        <div className="row flex">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div className="col flex justify-center items-center font-semibold w-full h-12" key={day}>
              {day}
            </div>
          ))}
        </div>
        {rows}
      </div>
    </div>
  );
};

export default Calendar;
