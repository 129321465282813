import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaChevronLeft } from "react-icons/fa";
import axios from "axios";

interface AmbassadorFormData {
  name: string;
  religion: string;
  gender: string;
  email: string;
  honorifics: string;
  username: string;
  picture: string;
  mobileNo: string;
  biography: string;
}
interface User {
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}


const ProfileEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [fetchedData, setFetchedData] = useState<AmbassadorFormData | null>(null);
  const [formData, setFormData] = useState<AmbassadorFormData | null>(null);
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) as User:null ;
  const [imageURL, setImageURL] = useState<string>('');
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAmbassadorData = async () => {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      try {
        const response = await fetch(`https://api.upfafrica.org/admin/user/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          console.error('Failed to fetch ambassador data');
          return;
        }
        const responseData = await response.json();
      const { primaryTag, ...filteredData } = responseData.data;

      // Check if primaryTag is valid, set to 0 if not
      const updatedData = {
        ...filteredData,
        primaryTag: (primaryTag && !isNaN(primaryTag) && primaryTag !== "") ? primaryTag : 10,
      };

      setFetchedData(updatedData);
      setFormData(updatedData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    

    fetchAmbassadorData();
  }, [id]);

  const handleSelectChange = (name: keyof AmbassadorFormData) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData!,
      [name]: value,
    });
  };
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
  
    // For non-picture inputs, update form data as usual
    setFormData({
      ...formData!,
      [name]: type === 'checkbox' ? checked : name === 'nationality' || name === 'userType' || name === 'regionId' || name === 'subRegionId' ? parseInt(value, 10) : value,
    });
  };
  

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
  
    try {
      // Prepare formData with error handling
      const formData = new FormData();
      formData.append('files', file);
  
      // Upload image with robust error handling
      const response = await axios.post(
        'https://fm.upfafrica.org/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${"e25ca86e136c8772548b88111b46546196e5a2d473e8328497f69a3a21936d3930d4ca4018fa414ab7b4b84dae67d26e0be033ccffd82e6511bca3929204f2326837c520ee51126ee701a6aba146f4abd9ee24d3e9a183e38f065f0bde5f42628978d36e727ade3110d7b64a00bda8ae7616201dc56e0915e4f3920030cdc70e"}`, // Replace with your actual token
          },
        }
      );
  
      if (!response.status || !response.data) {
        throw new Error('Failed to upload image');
      }
  
      // Handle expected data structure
      if (Array.isArray(response.data)) {
        const lastImageUrl = response.data.length > 0 ? response.data[response.data.length - 1].url : "";
        setImageURL(lastImageUrl);
        console.log('Image uploaded successfully:', imageURL);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Image upload error:', error);
      // Handle error (e.g., display a message to the user)
    }
  };

  const getTitleStyle = () => ({
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});
const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const token = sessionStorage.getItem('token');
  if (!token) {
    console.error('Token not found in localStorage');
    return;
  }

  try {
    const response = await fetch(`https://api.upfafrica.org/admin/user/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ ...formData!, picture: imageURL }),
    });

    if (!response.ok) {
      const Error = await response.json();
      console.error('Failed to submit form', Error);
      return;
    }
    const responseData = await response.json();
    navigate(`/individual/${id}`);
    console.log(responseData);
  } catch (error) {
    console.error('Error:', error);
  }
};

  
  if (!fetchedData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
          <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
      </div>
    );
  }
  const back=()=>{
    navigate(-1)
   }

  return (
    <>
     <button onClick={back} className='flex flex-row mb-4 hover'>
          <FaChevronLeft className='w-6 h-6' />
           <p className='text-xl font-semibold ml-3'>Back</p>
          </button>
    {(user?.userType === 8 || user?.userType === 4 || user?.userType === 7 || user?.userType === 6)  &&(
    <form className="max-w mx-auto p-4 " onSubmit={handleSubmit} style={getTitleStyle()}>
      <label className="block mb-2" >
        Name:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="name"
          value={formData?.name||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Religion:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="religion"
          value={formData?.religion||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Gender:
        <select
          className="w-full p-2 border rounded-md mt-1"
          name="nationality"

          value={formData?.gender}
          onChange={handleSelectChange('gender')}
        >
          <option value=''>Select Gender</option>
          <option value='Male'>Male</option>
          <option value='Female'>Female</option>
          <option value='Other'>Other</option>
        </select>
      </label>
      <label className="block mb-2" >
        Mobile No:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="mobileNo"
          value={formData?.mobileNo||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Honorifics:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="honorifics"
          value={formData?.honorifics||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        Email:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="email"
          name="email"
          value={formData?.email ||''}
         onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
       Biography:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="biography"
          value={formData?.biography||''}
          onChange={handleChange}
        />
      </label>
      <label className="block mb-2" >
        UserName:
        <input
          className="w-full p-2 border rounded-md mt-1"
          type="text"
          name="username"
          value={formData?.username||''}
          onChange={handleChange}
        />
        
      </label>
      
        <label htmlFor="picture" className="block mb-2">
  Picture:
  <input
    id="picture"
    className="w-full p-2 border rounded-md mt-1"
    // value={formData?.picture || ''}
    type="file"
    name="picture"
    onChange={handleImageChange}
  />
</label>

      <img src={`https://fm.upfafrica.org${imageURL}`||formData?.picture} alt="Uploaded" className='w-1/4 h-1/4 object-cover rounded-lg mb-4' />


      <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" type="submit">
        Submit
      </button>
    </form>
        )}
        {!(user?.userType === 8 || (user?.userType === 2 ) || (user?.userType === 4 ) || (user?.userType === 7 ) || (user?.userType === 6 ) ) && (
     <div className="min-h-screen flex flex-col justify-center items-center px-4">
     <div className="text-center max-w-lg">
       <FontAwesomeIcon icon={faLock} size="6x" className="text-red-600 mb-4" />
       <h1 className="text-3xl font-bold mb-2">Not Enough Permissions</h1>
       <p className="mb-6">
         Please contact Superiors to confirm your Authorizations Limits
       </p>
       
     </div>
   </div>
)}
        </>
  );
};

export default ProfileEdit;
