import React, { useState, useEffect } from 'react';
import { CiEdit } from 'react-icons/ci';
import { FaChevronLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import User from "../images/847-8474751_download-empty-profile.png";
interface UserProfile {
  id:any;
  name: string;
  picture: string;
  username: string;
  nationality: string;
  email: string;
  mobileNo: string;
  gender: string;
  birthDate: string;
}

const ProfileSettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const storedUser = localStorage.getItem('user');
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [nationList, setNationList] = useState<any[]>([]);
  const passwordchange=()=>{
         navigate('/passwordchange')
  }

  
  const token = sessionStorage.getItem('token');
const GetNations=async()=>{
  const nationResponse = await fetch('https://api.upfafrica.org/admin/nation/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      "query": {
        "isDeleted": false
      },
      "options": {
        "select": [
          "id",
          "name",
        ],
        "paginate": 666
      },
      "isCountOnly": false
    }),
  });

  if (!nationResponse.ok) {
    console.error('Nation request failed:', nationResponse.status, nationResponse.statusText);
    throw new Error('Nation request failed.');
  }
  const nationData = await nationResponse.json();
  setNationList(nationData.data.data);
}
useEffect(() => {
  GetNations()
  if (!storedUser) {
    navigate('/login');
  } else {
    setUserProfile(JSON.parse(storedUser));
  }
}, [navigate, storedUser]);
const getNationNameById = (id: any) => {
  const nation = nationList.find(n => n.id === id);
  return nation ? nation.name : '';
};
const back=()=>{
  navigate(-1)
 }
  return (
    <div className="min-h-screen p-8">
       <button onClick={back} className='flex flex-row mb-4 hover'>
          <FaChevronLeft className='w-6 h-6' />
           <p className='text-xl font-semibold ml-3'>Back</p>
          </button>
      <div className="max-w-4xl mx-auto">
        {userProfile && (
          <>
            <h2 className="text-4xl font-semibold text-gray-800 mb-6">Profile & Settings</h2>
            <div className="bg-white containerr rounded-lg shadow-md overflow-hidden px-6 py-8">
              <h3 className="text-2xl font-semibold  border-b pb-4 mb-4">Personal Information</h3>
              <div className=" flex -flex-row justify-between">
              <img
          src={`${userProfile?.picture ? `https://fm.upfafrica.org${userProfile?.picture}` : '/default-profile-image.png'}`}
          alt="Profile"
          className="w-48 h-48 object-cover rounded-full  containerr"
        />
        <button
                               className="text-blue-500 mr-4 ml-2"
                               onClick={() => navigate(`/profileEdit/${userProfile?.id}`)}
                           >
                               <CiEdit size={30} />
                           </button>
                 </div>
                <div className='flex flex-row justify-between'>
                
                
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <p><strong>Name:</strong> {userProfile.name||userProfile.username||"(no Name)"}</p>
                  <p><strong>Nationality:</strong>{getNationNameById(userProfile.nationality) ||'(no nationality)'}</p>
                  <p><strong>Email:</strong> {userProfile.email||"(no email)"}</p>
                  <p><strong>Phone:</strong> {userProfile.mobileNo||"(no mobileNo"}</p>
                  <p><strong>Gender:</strong> {userProfile.gender||"(no Gender)"}</p>
                  <p><strong>Birth date:</strong> {userProfile.birthDate||"(no BirtDate)"}</p>
                </div>
              </div>
              <div className="py-3 mt-5 flex flex-row gap-3">
                <h3 className="text-2xl font-semibold mb-4">Security Settings:</h3>
                <button className="bg-blue-500 text-white font-semibold p-2 rounded hover:bg-blue-600 transition-colors duration-200 ease-in-out" onClick={passwordchange}>
                  Change Password
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileSettingsPage;
