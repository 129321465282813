import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CiEdit } from 'react-icons/ci';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Select, Input, Empty, Pagination } from 'antd';
import { FadeLoader } from "react-spinners";
import Chart from "react-google-charts";

interface User {
    userType: number;
    subRegionId: number;
    regionId: number;
    nationality: number;
}

interface UserListProps {
    users: User[];
    deleteUserProp: any;
}

const Nation: React.FC<UserListProps> = ({ users, deleteUserProp }) => {
    const { id } = useParams();
    const [usersData, setUsersData] = useState<any[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
    const storedUser = localStorage.getItem('user');
    const User = storedUser ? JSON.parse(storedUser) as User : null;
    const nationData = localStorage.getItem('nations');
    const nationList = nationData ? JSON.parse(nationData) : [];
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [filterValue, setFilterValue] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 50;
    const { Option } = Select;
    const national = id;
    const navigate = useNavigate();
    const { darkMode } = useDarkMode();

    const getNationNameById = (id: any) => {
        if (!id || !Array.isArray(nationList) || nationList.length === 0) {
            return '';
        }
        const nation = nationList.find(n => n.id === id);
        return nation ? nation.name : '';
    };

    const formatChartData = () => {
        const chartData = [['', 'Number of People', { role: 'style' }]];
        const fetchedNationalities = filteredUsers.map(user => user.nationality).filter(n => n);
        const uniqueNationalities = Array.from(new Set(fetchedNationalities));
        
        uniqueNationalities.forEach((id) => {
            const nation = nationList.find((n: any) => n.id === id);
            const count = fetchedNationalities.filter(n => n === id).length;
            const colors = {
                1: '#b87333',
                2: '#3366cc',
                3: '#dc3912',
                4: '#ff9900',
                5: '#109618'
            };
            const color = colors[id as keyof typeof colors] || '#3366cc';
            chartData.push([(nation?.name) || 'Unknown', count, color]);
        });
        
        return chartData;
    };

    const getUserType = (user: any) => {
        if (user.leadershipStatus) return 'leadership';
        if (user.ambassadorStatus) return 'ambassador';
        return 'contacts';
    };

    useEffect(() => {
        // Initial data load
        const filteredData = users.filter((item: any) => 
            String(item.nationality) === String(national)
        );
        setUsersData(filteredData);
        setFilteredUsers(filteredData);
    }, [users, national]);

    useEffect(() => {
        let filtered = [...usersData];

        if (statusFilter) {
            filtered = filtered.filter(user => 
                getUserType(user).toLowerCase() === statusFilter.toLowerCase()
            );
        }

        if (filterValue) {
            const searchTerm = filterValue.toLowerCase();
            filtered = filtered.filter(user =>
                (user.name?.toLowerCase().includes(searchTerm) ||
                (user.position || user.upfPosition)?.toLowerCase().includes(searchTerm) ||
                getNationNameById(user.nationality)?.toLowerCase().includes(searchTerm))
            );
        }

        setFilteredUsers(filtered);
        setCurrentPage(1); // Reset to first page when filters change
    }, [statusFilter, filterValue, usersData]);

    const deleteUser = async (id: any) => {
        deleteUserProp(id);
    };

    const handleEdit = (id: any) => {
        navigate(`/EditAmbassador/${id}`);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const back = () => {
        navigate(-1);
    };

    const toTitleCase = (str: any) => {
        return str?.replace(/\w\S*/g, (txt: any) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }) || '';
    };

    const paginatedData = filteredUsers.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    if (usersData.length === 0) {
        return (
            <div className="flex justify-center items-center h-screen">
                <FadeLoader color="#367fd6" className="w-96 h-96" />
            </div>
        );
    }

    return (
        <div>
            <button onClick={back} className="flex flex-row mb-4 hover">
                <FaChevronLeft className="w-6 h-6" />
                <p className="text-xl font-semibold ml-3">Back</p>
            </button>
            <div className="w-[95%] containerrr rounded-lg p-3 ml-4 overflow-hidden shadow-lg bg-white">
                <p className="text-2xl font-semibold">Population</p>
                <Chart
                    className={`rounded-lg${darkMode ? '#fff' : '#000'}`}
                    width={'95%'}
                    height={'400px'}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={formatChartData()}
                    options={{
                        textStyle: { color: darkMode ? '#fff' : '#000' },
                        chartArea: { width: '60%' },
                        hAxis: {
                            title: '',
                            minValue: 0,
                            textStyle: { color: darkMode ? '#fff' : '#000' }
                        },
                        vAxis: {
                            title: '',
                            textStyle: { color: darkMode ? '#fff' : '#000' }
                        },
                        backgroundColor: darkMode ? '#333' : 'white',
                        legend: 'none',
                    }}
                />
            </div>
            <div className="w-[95%] bg-white rounded-lg mt-9 p-5 ml-5 containerrr">
                <div className="flex gap-4 mb-4">
                    <Select
                        value={statusFilter}
                        className="w-40"
                        onChange={(value: string) => setStatusFilter(value)}
                    >
                        <Option value="">All Status</Option>
                        <Option value="leadership">Leadership</Option>
                        <Option value="ambassador">Ambassador</Option>
                        <Option value="contacts">Contacts</Option>
                    </Select>
                    <Input
                        placeholder="Search by name, position, or nationality"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        className="w-64"
                    />
                </div>
                <table className="w-full">
                    <thead className="text-left">
                        <tr>
                            <th className="py-2">NO</th>
                            <th className="py-2 px-3">Name</th>
                            <th className="py-2">Position</th>
                            <th className="py-2">Nationality</th>
                            {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) && 
                                <th className="py-2">Action</th>
                            }
                        </tr>
                    </thead>
                    {paginatedData.length > 0 ? (
                        <tbody>
                            {paginatedData.map((user, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-1">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td className="py-2 px-3">{toTitleCase(user.name)}</td>
                                    <td className="py-2 ">
                                        {((user.position || user.upfPosition) && 
                                          (user.position || user.upfPosition).split(' ').slice(0, 120).join(' '))}
                                    </td>
                                    <td className="py-2 w-1/4">{user?.nationality && getNationNameById(user?.nationality)}</td>
                                    {(User?.userType === 8 || User?.userType === 4 || User?.userType === 7 || User?.userType === 6) && (
                                        <td className="py-2 flex flex-row">
                                            <FontAwesomeIcon
                                                onClick={() => deleteUser(user?.id)}
                                                className="cursor-pointer"
                                                color="#FF0000"
                                                icon={faTrash}
                                            />
                                            <CiEdit 
                                                className="cursor-pointer ml-2 w-11" 
                                                onClick={() => handleEdit(user?.id)} 
                                            />
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <Empty description="No Data Found" />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
                <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={filteredUsers.length}
                    onChange={handlePageChange}
                    style={{ textAlign: 'center', marginTop: '20px' }}
                    itemRender={(page, type, originalElement) => {
                        if (type === 'page') {
                            return (
                                <a style={{ color: darkMode ? 'white' : 'inherit' }}>
                                    {page}
                                </a>
                            );
                        }
                        return originalElement;
                    }}
                />
            </div>
        </div>
    );
};

export default Nation;